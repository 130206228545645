import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import CustomInput from '../../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../../assets/arrow-icon.png';
import UnavailableIcon from '../../../assets/unavailable.png';
import AvailableIcon from '../../../assets/available.png';
import SuccessIcon from '../../../assets/tick.png';

class Success extends React.Component{

  constructor(props){
    super(props);



    if(!this.props.location.state){
      this.props.history.push({
          pathname: '/booking/1'
      })
    }else{
      this.state = {
        loading:false,
        trackingId:!this.props.location.state['DATA']?(null):(this.props.location.state['DATA']['Detail'])?this.props.location.state['DATA']['Detail']['ImportDetail'][0]['TrackingID']:"Not Available Yet",
        responseId:"None",
        postDataId:this.props.location.state['postDataId']
      }
    }


    console.log(this.props.location.state)


  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  resendEmails = async()=>{

    this.setState({sent:true});
    
    if(this.lastClick && ((new Date().getTime() - this.lastClick) < 3000)){
      return;
    }

    this.lastClick = new Date().getTime();

    fetch(URLs.resendEmails,{
        method: "POST",
        body:JSON.stringify({
          POST_DATA_ID:parseInt(this.state.postDataId)
        }),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json'
        }

        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{
              alert("Your emails were re-sent.");
            })
          }else{
            res.json().then(async(data)=>{
              console.log(data);
            })

            return;
          }
      }).catch((error)=>{
        console.log(error);
      })


  }


  render(){
    if(!this.state.loading){
      return(
        <div>
          <Header></Header>

          <div className="content">
            <div className="success-view">
              <img className="success-icon" src={SuccessIcon} />
              <div style={{textAlign:'center', justifyContent:'center',alignItems:'center'}} className="success-text">
                Your payment was received successfully!<br/>
                Please check your emails. If you do not receive a confirmation and invoice you can resend below.<br/><br/>
                {(this.state.trackingId)?'Your Tracking ID is:'+this.state.trackingId:''}<br/><br/>

              {(this.state.sent)? <></>:
                <div style={{alignSelf:'center',position:'relative',left:'50%',transform:'translate(-50%,0%)'}} className="payment-submit-button" onClick={this.resendEmails}>Resend Emails
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div>
              }
              </div>
            </div>
          </div>

          <Footer></Footer>
        </div>
      )
    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default Success;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class IrelandDomestic extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../services">Services</a> / <a href="/">UK Domestic</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>UK Domestic</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            Transland offers an excellent UK domestic transport service, through its membership of the Palletways
            network. In operation since 1994, Palletways UK has over 120 depot members covering all parts of the UK
            on a daily basis.<br/><br/>
            Depending on your UK domestic transport requirements, you can avail of Premium (Next Day) and
            Economy (48 Hour) services at highly competitive rates. Value added services, such as AM / Timed /
            Saturday deliveries, are also available.<br/><br/>
            Customers can get full traceability on consignments from door to door, with real-time ETA and POD
            information provided.
          </div>
        </div>
        <Footer></Footer>
      </div>

    )
  }

}

export default IrelandDomestic;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class OnlineShipping extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image" ></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../">Technology</a> / <a href="/">Online Shipping Services</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>Online Shipping Services</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            Transland’s focus on technology has culminated in the development of its new Online Shipping services.
            <br/><br/>
            <div style={{fontWeight:"bold",color:"#8100c2"}}>Special Offers for pre-paid consignments</div><br/>
            Based on the concept used by low-cost airlines, and “first to market” in the transport and distribution sector, our Special Offers facility gives customers the opportunity to avail of greatly discounted rates on palletised freight for Ireland, UK and Europe, if shipments are booked and paid for in advance. The earlier you book, the cheaper the rate!
            <br/>
            The Special Offers facility of our online shipping services is aimed specifically at small to medium sized companies with 1-6 pallet consignments, where such savings could make a real difference to a company’s profitability.
            <br/>
            <Link to={'/register'}>CLICK HERE</Link> to register for our Special Offers section, and create your own Online Account (prepaid service)
            <br/><br/>
            <div style={{fontWeight:"bold",color:"#8100c2"}}>Online booking</div><br/>
            With our new online shipping services, existing account holders can now book their own consignments online, track their consignments through the Transland website, and receive an electronic POD within 2 minutes of delivery.
            <br/>
            We hope that our Online Shipping services will make a big difference to all our customers, saving them time, money and resources and giving them greater control over their shipments.
          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default OnlineShipping;

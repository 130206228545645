import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class PrivacyPolicy extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="content-header" style={{fontSize:"2rem"}}>Transland Group Privacy Policy</div>

          <div className="content-text" style={{fontSize:"1.2rem"}}>
          Transland Group ("we") are committed to protecting and respecting your privacy in accordance with the European General Data Protection Regulations.
          <br/><br/>
          This Privacy Policy provides an explanation as to what happens to any personal information that you provide to us, or that we collect from you, and your options regarding the ways in which your personal data is used. Please read the following carefully to understand our views and practices regarding your personal information and how we will treat it. This policy covers any and all potential forms of communication between you and Transland Group, including but not limited to: business transactions or queries, visiting our website, job applications, etc. If you have any requests concerning your personal information or any queries with regard to our data processing practices, please contact us by emailing gdprqueries@translandgroup.com.
          <br/><br/>
          For the purpose of this policy, the data controller is Transland International (UK) Limited trading as Transland Group, whose administration office is Unit 200 Northwest Business Park, Ballycoolin, Dublin 15.
          <br/><br/><br/><br/>


          INFORMATION WE MAY COLLECT ABOUT YOU
          <br/><br/>
          We may collect and process the following data about you:
          <br/><br/>
          Information you give us
          <br/><br/>
          You may give us personal information about you by filling in forms on our site (including our online booking engine), or by corresponding with us by telephone, e-mail or otherwise. The information you give us may include your name, address, e-mail address and phone number, financial and credit card information, personal description and photograph, and information regarding your shipment such as the details and nature of the products being delivered, their source and their destination.
          <br/><br/>
          Information we collect about you
          <br/><br/>
          With regard to each of your visits to our website, we may automatically collect the following information:
          <br/><br/>
          technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;
          information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call
          Information we receive from other sources
          <br/><br/>
          We may receive information about you from third parties we work with including, for example, business partners, sub-contractors, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies, etc.
          <br/><br/>
          Cookies
          <br/><br/>
          Our website pages partly use so-called cookies. Cookies do not harm your computer and do not contain viruses. Cookies serve to make our offer more user-friendly, effective and secure. Cookies are small text files that are stored on your computer and stored by your browser.
          <br/><br/>
          Most of the cookies we use are so-called "session cookies”. They are automatically deleted after your visit. Other cookies remain stored on your device until you delete them. These cookies allow us to recognise your browser the next time you visit.
          <br/><br/>
          You can set your browser to block or disable cookies, but this may limit the functionality of this website.
          <br/><br/>
          For more information, see our Cookie Policy.
          <br/><br/><br/><br/>


          USES MADE OF THE INFORMATION
          <br/><br/>
          We use information held about you in the following ways:
          <br/><br/>
          Information you give to us
          <br/><br/>
          We will use this information to:
          <br/><br/>
          carry out our obligations arising from any contracts entered into between you and us and to provide you with the information and services that you request from us;
          notify you of periodic promotional offers on our website;
          provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about;
          notify you about changes to our services;
          process any application for employment you might make to us;
          ensure that content from our site is presented in the most effective manner for you and for your computer;
          process any enquiry that you may submit to us, including any online
          Information we collect about you
          <br/><br/>
          We will use this information to:
          <br/><br/>
          administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;
          improve our site to ensure that content is presented in the most effective manner for you and for your computer;
          allow you to participate in any interactive features of our service;
          keep our site safe and secure;<br/><br/>
          measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;
          establish whether any of the services we may offer from time to time may be of interest to
          Information we may receive from other sources
          <br/><br/>
          We may combine this information with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).
          <br/><br/><br/><br/>


          MARKETING COMMUNICATIONS
          <br/><br/>
          Direct marketing will only be performed on the basis of consent (either written, electronic or verbal where recorded) and / or legitimate interest. This process will be conducted by the use of segmentation by sector and customer status, to ensure our messaging is relevant and of interest. The only personal data we will use for direct marketing purposes is your name and email address.
          <br/><br/>
          The personal data we collect will be used for the following purposes:
          <br/><br/>
          Sending direct marketing regarding our services;
          <br/><br/>
          Tracking when emails have been opened, how often, and whether links have been clicked within the email (and also when unsubscribing);
          Initiating contact on the basis of tracking this information.
          Our legal basis for processing the personal data is as follows:
          <br/><br/>
          Consent (where granted);<br/>
          Legitimate Interest (where consent has neither been granted nor refused).<br/>
          Any legitimate interests pursued by us are as follows:
          <br/><br/>
          We will only process your data if we believe that the message we are communicating will be of interest to your organisation;
          A legitimate interest assessment has been completed on your data, and we believe that you have an interest in the service we are communicating;
          Our segmentation process has highlighted your industry as one which can benefit from the service we are communicating.
          We will only collect, store and process the personal data we need to perform these direct marketing actions:
          <br/><br/>
          Name<br/>
          Email address<br/>
          If you send us enquiries, either electronically or by phone, your data will be stored in order to process the request and in case of follow-up questions. We will not share this data without your consent.
          <br/><br/>
          The information you provide will remain with us until:
          <br/><br/>
          you ask us to delete it<br/>
          you revoke your consent to storage<br/>
          we decide to delete the information for the purpose for data storage (for example, after your request has been processed).
          Mandatory statutory provisions – especially retention periods – remain unaffected.
          <br/><br/><br/><br/>


          JOB APPLICATIONS
          <br/><br/>
          We collect and process the personal data of job applicants for the purpose of completing the application process. The processing can also be done electronically, e.g. where an applicant submits the relevant application documents to us by e-mail or via a web form located on our website.
          <br/><br/>
          If a contract of employment results from the application, the transmitted data will be stored for the purpose of executing the employment relationship in compliance with the statutory provisions.
          <br/><br/>
          If the application does not result in a contract of employment, the application documents will be deleted six months after the appointment has been completed, provided that deletion does not prejudice any other legitimate interests of the controller, for example, a burden of proof in a legal procedure. This data may also be stored longer, should the applicant consent to this.
          <br/><br/><br/><br/>


          DISCLOSURE OF YOUR INFORMATION
          <br/><br/>
          We may share your information with selected third parties, including:
          <br/><br/>
          Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you;
          Analytics and search engine providers that assist us in the improvement and optimisation of our site;
          Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you;
          In order to comply with any legal obligation, or in order to enforce or apply our terms of use or terms and conditions of supply and other agreements, or to protect the rights, property, or safety of our employees, our customers, or others. This may include exchanging information with other companies and organisations for the purposes of fraud detection or prevention, and credit risk.
          <br/><br/><br/><br/>

          WHERE WE STORE YOUR PERSONAL DATA
          <br/><br/>
          The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"). It may also be processed by staff operating outside the EEA, who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your contract with us, the processing of your payment details and the provision of support services to us. By submitting your personal data, you agree to this transfer, storing or other forms of processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with Data Protection requirements.
          <br/><br/>
          When you book online with Transland Group, or access your online booking account, you do so through a secure server. The use of Secure Socket Layer (SSL) technology means that all personal information, such as your credit/debit card and address details, will be encrypted as it travels from your computer to our online booking system. SSL is an industry-standard method that is used to transmit personal information securely over the Internet. Our use of SSL and digital certificates ensures you are actually sending data to Transland Group, rather than a third party, so you can be confident of safely and confidentially completing your booking. For security purposes, and to comply with GDPR guidelines, we do not retain credit/debit card details on our website.
          <br/><br/>
          To maintain the accuracy of your data, as well as preventing unauthorised access and correct use of all data, we use the appropriate physical, electronic, and governance measures to secure the data we collect online. Please ensure that you keep your online account details confidential, and set a password that is difficult for others to guess.
          <br/><br/>
          All information you provide to us is stored on secure servers. Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use appropriate procedures and security features to try to prevent unauthorised access.
          <br/><br/><br/><br/>


          YOUR RIGHTS
          <br/><br/>
          You have the right to ask us not to process your personal data for marketing purposes. You can exercise this right by clicking "Unsubscribe” on any subsequent emails you may receive, or by sending an e-mail at any time to unsubscribe@translandgroup.com.
          <br/><br/>
          You have the right to access a copy of information we hold about you, and to have it either corrected if inaccurate, or deleted if required. Applications must be made in writing to The GDPR Department, Transland Group, Unit 200 Northwest Business Park, Ballycoolin, Dublin 15, or emailed to gdprqueries@translandgroup.com. Please note: We may request proof of identification for verification purposes. This information will also be handled in accordance with GDPR guidelines.
          <br/><br/>
          If you have created an online account, you can review and amend your personal details at any time by clicking on your account and entering your email address and password.
          <br/><br/>
          Our site may, from time to time, contain links to and from the websites of third parties. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies or how such third parties may process your personal information. Please check these policies before you submit any personal data to these websites.
          <br/><br/><br/><br/>


          CHANGES TO OUR PRIVACY POLICY
          <br/><br/>
          Transland Group will continue to improve and change operations and internal systems, where necessary, to comply with new legislation, including updates to our Privacy Policy. An updated copy will always be available on our website at https://www.translandgroup.com/privacy-policy and is subject to change without notice.


          <br/><br/><br/><br/>
          CONTACT
          <br/><br/>
          Questions, comments and requests regarding this Privacy Policy are welcomed and should be addressed to gdprqueries@translandgroup.com.
                    </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default PrivacyPolicy;

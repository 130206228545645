import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import CustomInput from '../../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../../assets/arrow-icon.png';
import UnavailableIcon from '../../../assets/unavailable.png';
import AvailableIcon from '../../../assets/available.png';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import SplitForm from '../../../components/cardForm.js';





class BookingFour extends React.Component{



  constructor(props){
    super(props);



      this.state = {
        loadingPromise:true,
        tc1:true,
        tc2:false,
        tc3:false,
        mailingList:false,
        loading:false,
        cards:[	{name:'Visa/ Visa Debit', code:'VISA'}, {name:'Mastercard', code:'MC'}, {name:'Laser (Ireland Only)', code:'Laser'}, {name:'UKDM (UK Only)', code:'UKDM'}],
        currencySymbols:{GBP:'£', EUR:'€'},
        chosenDate:'',
        error:'',
        cardNumber:'',
        cardCVC:'',
        cardExpiryMonth:'',
        cardExpiryYear:'',
        cardName:'',
        cardType:'VISA'
      }


    console.log(this.props.location.state);

    this.cardRef = React.createRef();



  }

  componentDidMount(){
    if(this.props.location.state){
      this.loadData();
      window.scrollTo(0, 0);
    }

    const query = new URLSearchParams(this.props.location.search);
    const dataId = query.get('dataId');
    const paymentAmount = query.get('paymentAmount');
    const Vat = query.get('Vat');
    const paymentAmountNoVAT = query.get('paymentAmountNoVAT');
    const currencyCode = query.get('currencyCode');

    if(dataId){
      this.setState({dataId,paymentAmountNoVAT,Vat,paymentAmount,currencyCode});
    }


    this.loadPromise();

  }

  /*  pk_live_5SnBVEG7ssm4BKeA1W8j1bN300ci2tN6je */
  /*  pk_test_51Gs86NLRJWNxm7nyslPgZnha7wwh5gigXq5ZCkbDp7tROyFWDbbFwjVu1r2tjIoWj9edXb8HnHQFjbLFpgEdKmR000TCxhBlk8 */

  loadPromise = async()=>{
    this.stripePromise = await loadStripe('pk_live_5SnBVEG7ssm4BKeA1W8j1bN300ci2tN6je');
    this.setState({loadingPromise:false});
  }

  loadData = ()=>{

  }

  stringifyPrice = (price) =>{

    let priceString = "";
    let euros = price.toString().split('.')[0];
    let cents = price.toString().split('.')[1];


    priceString = (euros.toString().length >= 4)? euros.substring(0,1)+","+euros.substring(1,euros.length): euros;

    if(cents == undefined) cents = 0;

    //cents = 12

    cents = parseInt(Math.round(parseFloat("0."+cents) * 100));

    console.log(cents);

    priceString = (cents.toString().length == 2)? priceString+"."+cents : priceString+"."+"0"+cents;

    return priceString;

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }

  submitDetails = async()=>{

    /* let expiryMonth = (this.state.cardExpiryMonth.toString().length == 1)? "0"+(this.state.cardExpiryMonth.toString()) : this.state.cardExpiryMonth.toString();


      if(this.state.cardNumber.toString().length != 16 || this.state.cardCVC.toString().length != 3 || expiryMonth.toString().length != 2 || this.state.cardExpiryYear.toString().length != 4 || this.state.cardName.length == 0){
        this.setState({error:"Your card details are not valid!"});
        return;
      }else if(!this.state.tc2){
        this.setState({error:"Please accept the terms & conditions!"});
        return;
      }else if(!this.state.tc3){
        this.setState({error:"Please accept the Privacy Policy!"});
        return;
      } */

      if(!this.state.intentId){
        await this.cardRef.current.handleSubmit();

        console.log(this.state.payload);

        if(this.state.payload.error){
          await this.setState({error:this.state.payload.error.toString()});
          console.log(this.state.payload.error);
          return;
        }
      }



      await this.setState({loading:true});

      let bookingFourData = {
        POST_DATA_ID:this.state.dataId,
        TYPE: this.state.cardType,
        NUMBER:this.state.cardNumber.toString(),
        NAME:this.state.cardName,
        EXDATE_MONTH:this.state.cardExpiryMonth.toString(),
        EXDATE_YEAR:parseInt(this.state.cardExpiryYear),
        CVV:parseInt(this.state.cardCVC),
        ACCEPT_POLICY:this.state.tc1 ? 1:0,
        ACCEPT_TC:this.state.tc2 ? 1:0,
        MAIL_LIST_YN:this.state.mailingList ? 1:0,
        PAYMENT_METHOD:this.state.payload.paymentMethod.id
      }

      console.log(bookingFourData);

      if(this.state.intentId){
        bookingFourData["INTENT_CODE"] = this.state.intentId;
      }


      fetch(URLs.bookingFourURL,{
          method: "POST",
          body:JSON.stringify(bookingFourData),
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
          }

          })
          .then((res) => {
            if(res.status == 200){
              res.json().then(async(data)=>{

                if((!data.success) && data.auth_needed){
                  this.setState({intentId:data.message, redirectUrl:data.redirect_url});
                  console.log(data);
                  return;
                }
                await this.setState({bookingResponse:data, error:""});
                this.props.history.push({
                    pathname: '/booking/successMobile',
                    state: data
                })
              })
            }else{
              res.json().then(async(data)=>{
                console.log(data);
              });
              this.setState({
                error:"There was an error with your card!",
                loading:false
              });
              return;
            }
        }).catch((error)=>{
          console.log(error);
        })

  }

  changeDetails = (details, data)=>{

    switch(details){
      case('card_number'):

        if(data.length <= 16){
        this.setState({cardNumber: data.replace(' ','')});
        }

      break;

      case('card_cvc'):
        if(data.length <= 3){
          this.setState({cardCVC: data.replace(' ','')});
        }
      break;

      case('card_expiry_month'):
        if(data.length <= 2){
          this.setState({cardExpiryMonth: data.replace(' ','')});
        }
      break;

      case('card_expiry_year'):
        if(data.length <= 4){
          this.setState({cardExpiryYear: data.replace(' ','')});
        }
      break;

      case('card_name'):
          this.setState({cardName: data});
      break;

      case('card_type'):
          this.setState({cardType: data});
      break;

      case('tc2'):
          this.setState({tc2: !this.state.tc2});
      break;

      case('tc3'):
          this.setState({tc3: !this.state.tc3});
      break;

      case('mailing_list'):
          this.setState({mailingList: !this.state.mailingList});
      break;


    }


  }

  structureDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  renderPriceNoVAT = ()=>{
    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice(Math.round(((this.state.paymentAmountNoVAT.toString().length == 4)? (this.state.paymentAmountNoVAT - ((this.state.currencyCode == "GBP")? 0:0)).toString()+"0" : this.state.paymentAmountNoVAT - ((this.state.currencyCode == "GBP")? 0:0))*100)/100);
    }

    return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice((this.state.paymentAmountNoVAT.toString().length == 4)? this.state.paymentAmountNoVAT.toString()+"0" : this.state.paymentAmountNoVAT);
  }

  renderVAT = ()=>{
    let customs = 0;

    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      customs = (this.state.currencyCode == "GBP")? 90:90;
    }

    console.log(customs);

    return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice((this.state.Vat.toString().length == 1)?  "0.00" : Math.round((this.state.Vat - customs) * 100) / 100);
  }

  renderCustoms = ()=>{

    let currencyAmount = (this.state.currencyCode == "GBP")? 90:90;
    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      return(
        <tr className="price-display">
          <td style={{width:"50%"}}>Customs Clearance</td>
          <td style={{textAlign:"left", width:"50%"}}>{this.state.currencySymbols[this.state.currencyCode]}{currencyAmount+".00"}</td>
        </tr>
      )
    }
  }



  render(){
    if(!this.state.loading && !this.state.loadingPromise){
      return(
        <div>

          <div className="content">

            <div className="booking-form-4">
              <div className="main-booking-form-header-4">Payment Details</div>

              <br/><br/>

              <Elements stripe={this.stripePromise}>
                <ElementsConsumer>
                  {({elements, stripe}) => (
                    <SplitForm context={this} ref={this.cardRef} elements={elements} stripe={stripe} />
                  )}
                </ElementsConsumer>
              </Elements>

              <br/><br/>
              <table className="price-table">
                  <tr className="price-display">
                    <td style={{width:"50%"}}>Delivery</td>
                    <td style={{textAlign:"left", width:"50%"}}>{this.renderPriceNoVAT()}</td>
                  </tr>



                  <tr className="price-display">
                    <td style={{width:"50%"}}>VAT</td>
                    <td style={{textAlign:"left", width:"50%"}}>{this.renderVAT()}</td>
                  </tr>

                  {this.renderCustoms()}




                  <tr className="price-display">
                    <td style={{width:"50%"}}>Total</td>
                    <td style={{textAlign:"left", width:"50%"}}>{this.state.currencySymbols[this.state.currencyCode]}{this.stringifyPrice((this.state.paymentAmount.toString().length == 4)? this.state.paymentAmount.toString()+"0" : this.state.paymentAmount)}</td>
                  </tr>
              </table>

              <div style={{textAlign:"left"}}>
                <input type="checkbox" id="checkbox1" style={{width:"30px",height:"30px"}} name="checkbox1" checked={this.state.tc3} onChange={(event)=>{this.changeDetails('tc3', event.target.value)}}></input>
                <label for="checkbox1"> I have read and understood <a href="/online-booking-policy.pdf">Transland's Online Shipping Policy</a></label><br/>

                <br/><br/>
                <input type="checkbox" id="checkbox1" style={{width:"30px",height:"30px"}} name="checkbox1" checked={this.state.tc2} onChange={(event)=>{this.changeDetails('tc2', event.target.value)}}></input>
                <label for="checkbox1"> I have read and understood Transland's Terms & Conditions: </label><br/>
                <a href="/irha_conditions.pdf">IRHA Conditions (Irish nationwide shipments)</a><br/>
                <a href="/rha_conditions.pdf">RHA Conditions (GB shipments)</a><br/>
                <a href="/cmr_conditions.pdf">CMR Conditions (European shipments)</a>



                <br/><br/>
                <input type="checkbox" style={{width:"30px",height:"30px"}} id="checkbox1" name="checkbox1" checked={this.state.mailingList} onChange={(event)=>{this.changeDetails('mailing_list', event.target.value)}}></input>
                <label for="checkbox1"> I would like to join Transland's Mailing List</label><br/>
              </div>

              <div className="booking-4-error">{this.state.error}</div>

              <div className="payment-submit-button" onClick={this.submitDetails}>Pay & Order
                <img src={arrow} className="home-form-arrow-icon" ></img>
              </div>
            </div>
        </div>

        </div>
      )
    }else if(this.state.intentId){
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <iframe src={this.state.redirectUrl} style={{width:'60vw',height:'50vh', marginBottom:'40px'}} />
            <div style={{color:"white",fontSize:"1.4rem"}}>Please confirm payment authorisation with your bank above, then resubmit below.</div>
            <div className="payment-submit-button" style={{border:"2px solid white"}} onClick={this.submitDetails}>Resubmit</div>
          </div>
        </div>
      )

    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default BookingFour;

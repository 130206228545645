import React from 'react';
import './formInput.css';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DatePicker from "react-datepicker";


class FormInput extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      currentDate:new Date(),
    }
  }

  MakeItem = (X)=>{
                return <option value={{X}}>{X}</option>;
            };

  renderInputComponent = ()=>{
    if(this.props.type == 'text'){
      return(
        <div>
          <input value={this.props.value} onChange={this.props.onChange} type="text" className="form-input-textbox"></input>
        </div>
      )
    }else if(this.props.type == 'password'){
      return(
        <div>
          <input type="password" className="form-input-textbox" value={this.props.value} onChange={this.props.onChange}></input>
        </div>
      )
    }else if(this.props.type == 'date'){

      return(
        <div>
          <DatePicker className="form-input-date" dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" selected={this.state.currentDate} onChange={date => {this.setState({currentDate: date})}} />
        </div>
      )
    }else{

      return(
        <div>
          <select className="form-input-dropdown">
            {
              this.props.options.map(this.MakeItem)
            }
          </select>
        </div>
      )
    }
  }

  render(){
    return(
      <div className="form-input">
        <div className="form-input-text">{this.props.name}</div>
        {this.renderInputComponent()}
      </div>
    )
  }

}

export default FormInput

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

import customer1 from '../../assets/customer1.png';
import customer2 from '../../assets/customer2.png';
import customer3 from '../../assets/customer3.png';
import customer4 from '../../assets/customer4.png';
import customer5 from '../../assets/customer5.png';

class AboutUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../about">About Us</a> / <a href="/">Our Customers</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>Our Customers</div>

          <div className="customer">
            <img src={customer1} className="customer-image" />

            <div className="customer-text">
              <div className="customer-header">Palletways UK</div>

              <div className="customer-quote">
              "Transland’s contribution to the haulage sector has had a wider impact on the industry, allowing businesses across the UK and Europe to move their goods and enable our economy to thrive and grow. Palletways is very proud to be part of the Transland success story, providing the pallet network technology and infrastructure to support their business to deliver the best possible service for their customers."
                <br/><div className="customer-name">- Barry Byers, Network Development Director</div>
              </div>
            </div>
          </div>

          <div className="customer">
            <img src={customer2} className="customer-image" />

            <div className="customer-text">
              <div className="customer-header">Excellence Limited</div>

              <div className="customer-quote">
              “Transland have achieved a high level of service to our customers. Their ability to adapt to our customer’s requirements ensures our confidence in them to deliver on time. The Transland team are extremely professional in their support to our business, with both administration staff and driver’s helpful and courteous.”
              <br/><div className="customer-name">- Ann Sweeney, Operations Supervisor</div>
              </div>
            </div>
          </div>

          <div className="customer">
            <img src={customer3} className="customer-image" />

            <div className="customer-text">
              <div className="customer-header">Moy Materials</div>

              <div className="customer-quote">
                "We have used Transland for the past 4 years and we find them to be highly professional, in terms of reliability and communication. They are also quick to adapt themselves to our changing requirements, and this makes them invaluable to our business."
                <br/><div className="customer-name">- Lorcan Byrne, Director</div>
              </div>
            </div>
          </div>

          <div className="customer">
            <img src={customer4} className="customer-image" />

            <div className="customer-text">
              <div className="customer-header">PalletXpress Limited</div>

              <div className="customer-quote">
              "Transland are essential to the ongoing success of the PalletXpress network. From the beginning they have driven the network forward, in terms of operations, technology and business development. Their professionalism has made a huge difference to PalletXpress and its members."
                <br/><div className="customer-name">- Tom Carr, Managing Director</div>
              </div>
            </div>
          </div>


          <div className="customer">
            <img src={customer5} className="customer-image" />

            <div className="customer-text">

              <div className="customer-quote">
              "I just wanted to say thank you very much for a really excellent service. I booked 2 x pallet collection deliveries from Kilkenny to Norfolk a few weeks ago and back again with this order. Each time the collections and deliveries were done very efficiently and professionally. The drivers were friendly and courteous, and when I rang the office for info on my collection the staff were polite and very helpful."
                <br/><div className="customer-name">- Caroline, Online Customer</div>
              </div>
            </div>
          </div>


        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default AboutUs;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class IrelandDomestic extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../services">Services</a> / <a href="/">Ireland Domestic</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>Ireland Domestic</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            Transland provides a premium Ireland Domestic Transport service for palletised freight within Ireland through its membership of PalletXpress, Ireland’s leading pallet network. In partnership with 23 other top-quality members located throughout the country, Transland can offer the following level of service to all its customers:
            <br/><br/>
            <ul>
            <li>Next-day deliveries to all cities and major towns throughout the island of Ireland</li>
            <li>Track consignments online, from collection through to delivery</li>
            <li>Electronic POD’s automatically sent via e-mail as soon as delivery is made</li>
            <li>Online booking facility via through our website</li>
            <li>Tail-lift vehicles available in all areas</li>
            <li>AM / Timed / Saturday deliveries also available</li>
            <li>Same-day service available (Dublin only)</li>
            </ul>
            <br/><br/>
            With reliability and traceability of service that is unequalled in the Irish market, you can be sure that our Ireland domestic transport service will be able to meet your palletised freight requirements.  </div>
          </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default IrelandDomestic;

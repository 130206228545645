import React from 'react';
import './header.css';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import menuIcon from '../assets/mobile-menu.png';
import xButton from '../assets/x-button.png';

import googlePlay from '../assets/google-play.png';
import appStore from '../assets/app-store.svg';




class Header extends React.Component{

  constructor(props){

    super(props);

    this.state = {};

    this.mobileApp = props.mobileApp;
    this.baseContext = props.baseContext;

    if(!this.baseContext) return;

    const query = new URLSearchParams(this.baseContext.props.location.search);
    const token = query.get('setToken');

    if(token){
      this.setState({displayOff:true});
    }


  }


  navigate = ()=>{

  }

  openMobileMenu = ()=>{
    let i = 0;
    document.getElementById("side-menu").style.visibility = 'visible';

    var interval = setInterval(function(){
      if(i <= 55){
        document.getElementById("side-menu").style.width = `${i}%`;
        i++;
      }else{
        document.getElementById("side-menu-x").style.visibility = 'visible';
        clearInterval(interval);
      }
    }, 4);
  }

  closeMobileMenu = ()=>{
    let i = 55;

    var interval = setInterval(function(){
      if(i >= 0){
        document.getElementById("side-menu").style.width = `${i}%`;
        i--;
      }else{
        document.getElementById("side-menu-x").style.visibility = 'hidden';
        document.getElementById("side-menu").style.visibility = 'hidden';
        clearInterval(interval);
      }
    }, 4);
  }

  renderHeader1 = ()=>{
    if(this.props.type == "dashboardHeader"){
      return(
        <div className="header-1-main">
          <img src={googlePlay} className="app-store-badge" />
          <img src={appStore} className="app-store-badge" />
          <div className="header-1-button">Welcome {this.props.clientName}</div>
          <div className="dashboard-logout-button" onClick={this.props.context.logOut}>Log Out</div>
        </div>

      )
    }else{
      return(
        <div className="header-1-main">
          <Link to={'/shipping-between-eu-and-gb-via-dublin'}><div className="header-1-button">Shipping between the EU & GB via Dublin</div></Link>
          <Link to={'/client-login'}><div className="header-1-button">Client Login</div></Link>
          <Link to={'/register'}><div className="header-1-button">Register</div></Link>
          <Link to={'/tracking'}><div className="header-1-button">Tracking</div></Link>
          <img src={googlePlay} className="app-store-badge" />
          <img src={appStore} className="app-store-badge" />
        </div>

      )
    }
  }

  render(){

    if(this.state.displayOff) return;

    return(
      <div className="header">

        <div id="side-menu" className="side-menu">
          <img src={xButton} id="side-menu-x" className="side-menu-x" onClick={this.closeMobileMenu} />
          <div className="side-menu-content">
            <Link to={'/'}><div className="side-menu-item">Home</div></Link>
            <Link to={'/about'}><div className="side-menu-item">About Us</div></Link>
            <Link to={'/partners'}><div className="side-menu-item">Partners</div></Link>
            <Link to={'/services'}><div className="side-menu-item">Services</div></Link>
            <Link to={'/technology'}><div className="side-menu-item">Technology</div></Link>
            <Link to={'/contact-us'}><div className="side-menu-item">Contact Us</div></Link>
            <Link to={'/payments'}><div className="side-menu-item">Payments</div></Link>
          </div>
        </div>

        {this.renderHeader1()}

        <div className="header-2-main">
          <Link to={'/'}><img src={logo} className="header-2-logo" /></Link>

          <div className="header-2-menu">
            <Link to={'/'}><div className="header-2-menu-item">Home</div></Link>
            <div class="dropdown">
              <Link to={'/about'}><span className="header-2-menu-item">About Us</span></Link>
              <div class="dropdown-content">
              <a href="https://news.translandgroup.com"><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">News</span></a><br/>
                <Link to={'/about-us/brexit'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Brexit</span></Link>
                <Link to={'/about-us/company-profile'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Company Profile</span></Link><br/>
                <Link to={'/about-us/management-team'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Management Team</span></Link>
                <Link to={'/about-us/customers'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Customers</span></Link><br/>
                <Link to={'/about-us/contacts'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Contacts</span></Link><br/>
                <Link to={'/downloads'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Downloads</span></Link>

              </div>
            </div>
            <Link to={'/partners'}><div className="header-2-menu-item">Partners</div></Link>
            <div class="dropdown">
              <Link to={'/services'}><span className="header-2-menu-item">Services</span></Link>
              <div class="dropdown-content">
                <Link to={'/services_/ireland-uk-ireland'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Ireland-UK-Ireland</span></Link>
                <Link to={'/services_/ireland-domestic'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Ireland-Domestic</span></Link>
                <Link to={'/services_/uk-domestic'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">UK-Domestic</span></Link>
                <Link to={'/services_/european_pallet_distribution'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">European</span></Link>
                <Link to={'/services_/international-freight-forwarding'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">International</span></Link>
              </div>
            </div>
            <div class="dropdown">
              <Link to={'/technology'}><span className="header-2-menu-item">Technology</span></Link>
              <div class="dropdown-content">
                <Link to={'/technology_/online-shipping-services'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Online Shipping Services</span></Link>
                <Link to={'/technology_/pod-capture'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">Electronic POD</span></Link>
                <Link to={'/technology_/gps-tracking-system'}><span style={{color:"#590094",paddingTop:"5px"}} className="header-2-menu-item-drop">GPS Tracking System</span></Link>
              </div>
            </div>
            <Link to={'/contact-us'}><div className="header-2-menu-item">Contact Us</div></Link>
            <Link to={'/payments'}><div className="header-2-menu-item">Payments</div></Link>
          </div>

          <img src={menuIcon} className="menu-icon" onClick={this.openMobileMenu}/>
        </div>

      </div>
    )
  }


}


export default Header

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

import translandTrucks from '../../assets/transland-trucks.jpg';
import kieran from '../../assets/kieran.jpg';
import patrick from '../../assets/patrick.jpg';
import helen from '../../assets/helen.jpg';
import kelvin from '../../assets/kelvin.jpg';
import mandy from '../../assets/mandy.png';
import luke from '../../assets/luke.png';
import gary from '../../assets/gary.png';
class AboutUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../about">About Us</a> / <a href="/">Management Team</a></div>

          <div className="content-header" style={{fontSize:"1.6vw"}}>Management Team</div>

          <div className="team-member">
            <img src={kieran} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Managing Director: Kieran Conlon, MILT</div>

              <div className="team-member-bio">
              Kieran has been involved in the freight forwarding business for over 40 years, working in Sales and
Operations, and gaining specialist knowledge of European road freight as well as Far East ocean freight.
Kieran has been Managing Director since the company’s formation in 1993 and has always played a hands-
on role in the company’s development. A true innovator, Kieran was the driving force behind the online
booking facility available on the Transland website. Prior to the introduction of Brexit, Kieran had the vision
to assemble a highly experienced international team to cater for customers’ Customs Clearance
requirements and capitalise on new global opportunities.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={patrick} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Financial Director: Patrick Dowling, FCCA</div>

              <div className="team-member-bio">
              Patrick is a Chartered Certified Accountant. He has worked with Transland International for over 25 years,
              and is responsible for directing the financial and strategic decisions of the Group. Displaying an aptitude for
              IT, Patrick was heavily involved in the implementation of Transland’s Freight Management System and, in
              particular, the development of its Financial Management module. He continues to manage the company’s
              network infrastructure, and is constantly exploring ways in which technology can benefit Transland in terms
              of cost reduction, operational efficiency, and improved customer experience.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={helen} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Administration Director: Helen Lee</div>

              <div className="team-member-bio">
              Helen joined the company in 1997 as Personal Assistant to the Managing Director. Since then she has
              undertaken various roles, including Quality, Finance, Operations, Health &amp; Safety, HR and IT. Helen was also
              involved for the implementation of the Transland’s IT systems, and responsible for the maintenance of its
              quality standards. She continues to direct and oversee the Group’s administrative procedures, including
              Health &amp; Safety and HR, to ensure achievement of performance targets and guarantee customer satisfaction.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={mandy} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Sales Director: Mandy Spain</div>

              <div className="team-member-bio">
              Mandy joined Transland in 2005, with an extensive background in Hospitality &amp; Tourism Management. Her
              original role as Account Manager focused on establishing new business and developing long-term client
              relationships. Since becoming Sales Director of Transland in 2014, Mandy has been responsible for
              maximising growth opportunities, sales performance and customer satisfaction through the development
              and implementation of key strategies.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={gary} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Commercial Director: Gary Owen</div>

              <div className="team-member-bio">
                Gary joined Transland in 2023, and heads up Transland’s Commercial Team in the UK focusing on domestic and international business development. Gary has a wealth of commercial experience in the freight sector, having recently spent 4 years with the Palletways UK network in a similar role. Prior to this, he was Group Sales Director with NGC Logistics. With this background, Gary has all the skills necessary to take Transland to the next level in terms of new commercial opportunities.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={luke} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Director of Operations: Luke Conlon</div>

              <div className="team-member-bio">
              Luke joined Transland in 2021 and quickly learned the ropes, gaining experience in a variety of roles
              including Operations, Customs and Sales. In 2023 he was appointed General Manager, with responsibility
              for the smooth and efficient operation of the company’s Dublin depot. Luke was co-opted onto the Board of
              Directors in early 2024. As Director of Operations, Luke oversees the strategic development of Transland’s
              operation while also managing the structure and resources of the various departments to maximise
              productivity and provide long-term opportunities for staff.
              </div>
            </div>
          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default AboutUs;

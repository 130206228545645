import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import FormInput from '../../../components/formInput.js';

import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import arrow from '../../../assets/arrow-icon.png';
import watermark from '../../../assets/watermark.png';
import settings from '../../../assets/settings.png';
import tracking from '../../../assets/tracking.png';
import plus from '../../../assets/plus.png';

import URLs from '../../../constants/Api.js';

class Dashboard extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:true,
                  errors:"",
                  loadingOrders:true,
                  loadingDetails:true,
                  currencySymbols:{GBP:'£', EUR:'€'},
                  orders:[]
                };


  }


  componentDidMount = async()=>{
    window.scrollTo(0, 0);
    await this.validateLogin();
    this.loadUserDetails();
  }

  logOut = async()=>{
    const cookies = new Cookies();
    await cookies.remove('accessToken',  { path: '/' });

    this.props.history.push("/client-login");
  }

  validateLogin = ()=>{

    const cookies = new Cookies();

    if(cookies.get('accessToken') == null){

      this.props.history.push("/client-login");

    }else{
      this.setState({loading:false, token:cookies.get('accessToken')});
    }

  }

  loadUserDetails = ()=>{
    const cookies = new Cookies();

    console.log('2');

    fetch(URLs.userDetailsURL,{
        method: "GET",
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+cookies.get('accessToken')
        },
        })
        .then((res) => {
          console.log('A');
          if(res.status == 200){
            res.json().then(async(data)=>{
              await this.setState({name:data.customer.NAME, loadingDetails:false});
              console.log(data);
            })
          }else{
            console.log('B');
            res.json().then(async(data)=>{
              console.log(data);
            })
            this.setState({
              error:"There was an error loading",
              loadingDetails:false
            });
            return;
          }
      }).catch((error)=>{
        console.log(error);
      });

      fetch(URLs.ordersURL,{
          method: "GET",
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+cookies.get('accessToken')
          },
          })
          .then((res) => {
            console.log('3');
            if(res.status == 200){
              res.json().then(async(data)=>{
                await this.setState({orders:data, loadingOrders:false});
                console.log(data);
              })
            }else{
              console.log('4');
              res.json().then(async(data)=>{
                console.log(data);
              })
              this.setState({
                error:"There was an error loading",
                loadingOrders:false
              });
              return;
            }
        });
  }

  trackOrder = (orderNumber)=>{

    let orderData = {
      number:orderNumber
    }

    this.props.history.push({
        pathname: '/tracking',
        state: orderData
    });

  }



  renderErrors = ()=>{
    if(this.state.error != ""){
      return(
        <div className="errors-container">
          {this.state.error}
        </div>
      )
    }
  }

  formatDate = (date)=>{
    let dateObj = new Date(date);
    return ((dateObj.getDate().toString().length == 1)? "0"+dateObj.getDate():dateObj.getDate())+"/"+((dateObj.getMonth().toString().length == 1)? "0"+dateObj.getMonth():dateObj.getMonth())+"/"+dateObj.getFullYear();
  }

  render(){
    if(this.state.loadingDetails || this.state.loadingOrders){
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }else{
      return(
        <div>
          <Header type={"dashboardHeader"} clientName={this.state.name} context={this}></Header>
            <div className="dashboard-content-container">
              <div className="dashboard-navigation-row">
                <a href="/dashboard/settings"><div className="navigation-tile">
                  <img src={settings} className="navigation-tile-icon" />
                  <div className="navigation-tile-text">My Settings</div>
                </div></a>


                <a href="/tracking"><div className="navigation-tile">
                  <img src={tracking} className="navigation-tile-icon" />
                  <div className="navigation-tile-text">Tracking</div>
                </div></a>


                <a href="/booking/1"><div className="navigation-tile">
                  <img src={plus} className="navigation-tile-icon" />
                  <div className="navigation-tile-text">New Order</div>
                </div></a>

              </div>

              <div className="dashboard-order-history">
                <div className="dashboard-order-header">My Order History</div>
                <div className="my-orders">
                  {
                    this.state.orders.map((order, index)=>{
                      return(
                      <div className="order-row">
                        <div className="order-data-left">
                          <div className="order-data-to">
                            From: {order['INFO']['booking']['POST_3']['FROM']['NAME']}<br/>
                            To: {order['TO_AD'].split(" Company: ")[1]}
                          </div>
                          <br/>
                          <div className="order-data-date">{this.formatDate(order['DATE'])}</div>
                        </div>
                        <div className="order-data-right">
                          <span className="order-data-tracking-button" onClick={()=>{this.trackOrder(order['PW_TRACKING'])}}>Track Order</span>
                        </div>
                      </div>
                      )
                    })
                  }
                </div>
              </div>

            </div>
          <Footer></Footer>
        </div>
      )
    }
  }

}

export default Dashboard;

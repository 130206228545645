import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class PODCapture extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image" ></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../">Technology</a> / <a href="/">Electronic POD-Capture</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>Electronic POD Capture</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            Transland has implemented an Electronic POD Capture system for all its UK and Ireland deliveries. Through the use of hand-held devices, Proof of Delivery information can be captured electronically and made available to customers within seconds.
            <br/><br/>
            With our new electronic POD capture system, all drivers are equipped with portable PDA devices, which are uploaded with job details each morning.
            <br/>As deliveries are carried out, the driver uses the device to record the name and signature of the consignee, as well as any other relevant information.
            This information is then transmitted to Transland’s central database, through which customers are automatically sent an electronic Proof of Delivery (or ePOD) by e-mail. Electronic POD’s can also be found through the Transland website, by searching under the relevant job number.
            <br/><br/>
            The benefits of our new electronic POD capture system have been considerable and immediate.
            For customers, it means knowing straight away when that important delivery has been made – electronic POD Capture means no more phone calls or waiting for paperwork to be returned. Transland has also noticed significant improvements in its operational and administrative efficiency.
            <br/><br/>
            Moreover, electronic POD Capture further underlines Transland’s commitment to "working smarter".
          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default PODCapture;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

class InternationalFreightForwarding extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="iff-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../services">Services</a> / <a href="/">International Freight Forwarding</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>International Freight Forwarding</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            Transland has established a dedicated Solutions Department to cater for freight that may not move through our standard infrastructure. This may include Same-Day deliveries, non-palletised freight, and also worldwide shipping. Building an extensive network of overseas agents, we can provide you with the most competitive rates on international freight forwarding shipments.
            <br/><br/>
            In addition to our palletised freight services, we can offer groupage services domestically, to and from the UK, Europe and worldwide.
            <br/><br/>
            With our international freight forwarding service, we can offer a “one stop shop” to customers looking for one carrier to look after their worldwide shipping needs.
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default InternationalFreightForwarding;

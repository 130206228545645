import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

class UkDomestic extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="uk-domestic-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../about">About Us</a> / <a href="/">Brexit</a></div>

          <div className="uk-content-header">Brexit</div>

          <div className="uk-content-text">
          Brexit is now upon us. All companies trading between Great Britain and the Republic of Ireland must ensure that they are adequately prepared. Transland urges all customers who are trading with GB to make sure they have the proper processes in place. In this way, we can minimise the potential negative impacts of Brexit, and support the efficient and timely flow of trade.
          <br/><br/>
          <b style={{color:"#8c07d0"}}>Shipping Goods To/From GB</b>
          <br/><br/>
          From 1st January 2021 all consignments to/from GB will be subject to an additional Customs Clearance charge per consignment, which is based on the number of Commodity Codes entered.
          <br/>

          For online bookings to/from GB, you can use our Commercial Invoice Template, or upload your company’s / your supplier’s Commercial Invoice ensuring it contains all the information on our Commercial Invoice Checklist.
          <br/><a className="template-link" href="/Commercial_Invoice_Template.xlsx"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Download Commercial Invoice Template</span></a>
          <br/><a className="template-link" href="/Commercial_Invoice_Checklist.pdf"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Download Commercial Invoice Checklist</span></a>
          <br/><br/>
          Where Duty and VAT apply, the amount will be calculated based on the Commodity Code/s and value of the goods, and MUST be paid by the importer before delivery can be carried out. It is important that sufficient funds are maintained in the importers’ TAN account for this purpose.
          <br/><br/>
          <b style={{color:"#8c07d0"}}>Duty on Goods Imported from GB</b>
          <br/><br/>
          A trade deal was agreed between the UK and the EU at the end of 2020. However, the EU/UK trade deal only applies to products coming into the EU from GB that are made in GB. If goods are purchased in GB but originate from outside the EU (as indicated on the Commercial Invoice), then Duty may be applicable.
          <br/><br/>
          <b style={{color:"#8c07d0"}}>SPS Products</b>
          <br/><br/>
          As a result of Brexit, certain product categories moving between Europe and GB are now subject to Sanitary & Phytosanitary (SPS) controls and inspections by the Department of Agriculture, Marine & Fisheries. As a result, these products must be isolated and shipped separately from normal Express freight in order to avoid contamination and delays to other product. Such products include:
          <br/>• Food and food products
          <br/>• Animals and animal products
          <br/>• Wood and wood products
          <br/>• Plants, soil, etc.
          <br/><br/>
          Unfortunately Transland is not currently accepting bookings for SPS / Special Category consignments to/from GB. However, we intend to introduce a dedicated service for SPS products in the near future. Please contact Transland on +353 (0)1 8293222 / +44 (0)1543 445656 for further details.
          <br/><br/>
          <b style={{color:"#8c07d0"}}>Customs Forms</b>
          <br/><br/>
          Please find below links to various information sheets and authorisation forms, which will help you to prepare adequately for Brexit. You can download any the following documents by clicking on each link:
          <br/><br/>
          <b style={{color:"#8c07d0"}}>Shipping from GB to the Republic of Ireland:</b>
          <br/><br/>

          <a href="/ROI_Importer_Guide.pdf">ROI Importer Guide – Forms Required</a><br/>
          <a href="/Direct_Rep_GB.pdf">Transland Direct Representation Authorisation Form – GB (updated April 2021)</a><br/>
          <a href="/Direct_Rep_IE.pdf">Transland Direct Representation Authorisation Form – IE (updated September 2021)</a><br/>
          <a href="/Revenue_Deferment.pdf">Irish Revenue Deferment Request Form (AEP5a)</a><br/>
          <a href="/Commercial_Invoice_Template.pdf">Commercial Invoice Template</a>
          <br/><br/>
          <b style={{color:"#8c07d0"}}>Shipping from the Republic of Ireland to GB:</b><br/><br/>
          <a href="/ROI_Exporter.pdf">ROI Exporter Guide – Forms Required</a><br/>
          <a href="/Direct_Rep_IE.pdf">Transland Direct Representation Authorisation Form – IE (updated September 2021)</a><br/>
          <a href="/Direct_Rep_GB.pdf">Transland Direct Representation Authorisation Form – GB (updated April 2021)</a><br/>
          <a href="/C1207N.pdf">UK HMRC Deferment Request Form (C1207N)</a><br/>
          <a href="/Commercial_Invoice_Template.pdf">Commercial Invoice Template</a><br/>
          <br/><br/>
          Please note that the above forms only need to be filled out once per company, and does not prevent you from using other Transport / Customs Clearance Agents.
          <br/><br/>
          Should you have any specific queries, you can also contact the Customs Departments in our Ireland and UK offices:<br/>
          <b>Ireland: +353 (0)1 8293222 (Extension 3)</b><br/>
          <b>UK: +44 (0) 1543 445656</b>
          <br/><br/>
          View our short video explaining Commercial Invoices:<br/>

          <video style={{width:'60%', marginTop:'4vh' ,border:"3px solid grey"}} controls>
            <source src="/video1.mp4" type="video/mp4" />
          </video>
          <video style={{width:'60%', marginTop:'4vh' , border:"3px solid grey"}} controls>
            <source src="/video2.mp4" type="video/mp4" />
          </video>

          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default UkDomestic;

import React from 'react';
import './settingsInput.css';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DatePicker from "react-datepicker";


class CustomInput extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      currentDate:new Date(),
    }
  }


  renderInputComponent = ()=>{
    if(this.props.type == 'text'){
      return(
        <div>
          <input value={this.props.value} onChange={this.props.onChange} type="text" className="settings-input-textbox"></input>
        </div>
      )
    }
  }

  render(){
    return(
      <div className="settings-input">
        <div className="settings-input-text">{this.props.name}</div>
        {this.renderInputComponent()}
      </div>
    )
  }

}

export default CustomInput

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import AboutUs from './screens/aboutUs/index';
import CompanyProfile from './screens/companyProfile/index';
import ManagementTeam from './screens/managementTeam/index';
import Customers from './screens/customers/index';
import Contacts from './screens/contacts/index';
import Partners from './screens/partners/index';
import Technology from './screens/technology/index';
import ContactUs from './screens/contactUs/index';

import OnlineShipping from './screens/onlineShipping/index';
import PODCapture from './screens/pod-capture/index';
import GPSTracking from './screens/gps-tracking/index';

import Services from './screens/services/index';

import IrelandDomestic from './screens/irelandDomestic/index';
import IrelandUK from './screens/irelandUK/index';
import European from './screens/european/index';

import InternationalFreightForwarding from './screens/international-freight-forwarding/index';
import UKDomestic from './screens/uk-domestic/index';
import PersonalEffects from './screens/personal-effects/index';

import ClientLogin from './screens/clientLogin/index';
import ClientRegister from './screens/register/index';
import Tracking from './screens/tracking/index';

import Dashboard from './screens/dashboard/orders/index';
import DashboardSettings from './screens/dashboardSettings/index';

import Booking1 from './screens/booking/1';
import Booking2 from './screens/booking/2';
import Booking3 from './screens/booking/3';
import Booking4 from './screens/booking/4';
import Booking4Mobile from './screens/booking/4Mobile';
import BookingSuccess from './screens/booking/success';
import BookingSuccessMobile from './screens/booking/successMobile';

import PrivacyPolicy from './screens/privacy-policy/index';

import ProductCalculator from './screens/productCalculator';

import ResetPassword from './screens/resetPassword';

import Downloads from './screens/downloads';

import Revenue from './screens/revenue';

import RateRequest from './screens/rateRequest';


import Terms from './screens/terms';

import UKDomesticService from './screens/ukDomesticService';

import Aid from './screens/aid/index';

import CustomsPayment from './screens/customsPayment';

const routing = (
  <Router>
      <Switch>
        <Route path="/dashboard/orders" component={Dashboard} />
        <Route path="/dashboard/settings" component={DashboardSettings} />
        <Route path="/about/" component={AboutUs} />
        <Route path="/about-us/company-profile" component={CompanyProfile} />
        <Route path="/about-us/management-team" component={ManagementTeam} />
        <Route path="/about-us/customers" component={Customers} />
        <Route path="/about-us/contacts" component={Contacts} />
        <Route path="/partners" component={Partners} />
        <Route path="/technology" component={Technology} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/technology_/online-shipping-services" component={OnlineShipping} />
        <Route path="/technology_/pod-capture" component={PODCapture} />
        <Route path="/technology_/gps-tracking-system" component={GPSTracking} />
        <Route path="/services/" component={Services} />
        <Route path="/services_/ireland-domestic" component={IrelandDomestic} />
        <Route path="/services_/ireland-uk-ireland" component={IrelandUK} />
        <Route path="/services_/european_pallet_distribution" component={European} />
        <Route path="/services_/international-freight-forwarding" component={InternationalFreightForwarding} />
        <Route path="/about-us/brexit" component={UKDomestic} />
        <Route path="/services_/uk-domestic" component={UKDomesticService} />
        <Route path="/services_/personal-effects" component={PersonalEffects} />
        <Route path="/client-login" component={ClientLogin} />
        <Route path="/register" component={ClientRegister} />
        <Route path="/tracking" component={Tracking} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/product-calculator" component={ProductCalculator} />
        <Route path="/booking/1" component={Booking1} />
        <Route path="/booking/2" component={Booking2} />
        <Route path="/booking/3" component={Booking3} />
        <Route path="/booking/4" component={Booking4} />
        <Route path="/booking/4Mobile" component={Booking4Mobile} />
        <Route path="/booking/success" component={BookingSuccess} />
        <Route path="/booking/successMobile" component={BookingSuccessMobile} />
        <Route path="/downloads" component={Downloads} />
        <Route path="/terms" component={Terms} />
        <Route path="/shipping-between-eu-and-gb-via-dublin" exact component={Revenue} />
        <Route path="/rate-request" exact component={RateRequest} />
        <Route path="/payments" exact component={CustomsPayment} />
        <Route path="/" exact component={App} />
      </Switch>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import CustomInput from '../../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../../assets/arrow-icon.png';
import UnavailableIcon from '../../../assets/unavailable.png';
import AvailableIcon from '../../../assets/available.png';
import SuccessIcon from '../../../assets/tick.png';

class Success extends React.Component{

  constructor(props){
    super(props);



    if(!this.props.location.state){
      this.props.history.push({
          pathname: '/booking/1'
      })
    }else{
      this.state = {
        loading:false,
        trackingId:(this.props.location.state['DATA']['Detail'])?this.props.location.state['DATA']['Detail']['ImportDetail'][0]['TrackingID']:"Not Available Yet",
        responseId:"None"

      }
    }


    console.log(this.props.location.state)


  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }





  render(){
    if(!this.state.loading){
      return(
        <div>
          <div className="content">
            <div className="success-view">
              <img className="success-icon" src={SuccessIcon} />
              <div className="success-text">
                Your payment was received successfully!<br/>
                A confirmation email will be sent to you shortly.<br/><br/>
                Your Tracking ID is: {this.state.trackingId}
              </div>
            </div>
          </div>

        </div>
      )
    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default Success;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

import translandTrucks from '../../assets/transland-trucks.jpg';
import kieran from '../../assets/kieran.jpg';
import patrick from '../../assets/patrick.jpg';
import helen from '../../assets/helen.jpg';
import kelvin from '../../assets/kelvin.jpg';
import mandy from '../../assets/mandy.png';
class Contacts extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="contacts-content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../about">About Us</a> / <a href="/">Contacts</a></div>

          <div className="content-header-main">Contacts</div>

          <div className="contact-group">
            <div className="contact-header-main">
              Key Contacts
            </div>

            <table className="contact-table">
              <tr>
                <td className="contact-column-left">Group Managing Director:</td>
                <td className="contact-column-right">Kieran Conlon</td>
              </tr>

              <tr>
                <td className="contact-column-left">Financial Director:</td>
                <td className="contact-column-right">Patrick Dowling</td>
              </tr>

              <tr>
                <td className="contact-column-left">Sales Director:</td>
                <td className="contact-column-right">Mandy Spain</td>
              </tr>

              <tr>
                <td className="contact-column-left">Administration Director:</td>
                <td className="contact-column-right">Helen Lee</td>
              </tr>

              <tr>
                <td className="contact-column-left">Global Forwarding Director:</td>
                <td className="contact-column-right">John Grogan</td>
              </tr>

              <tr>
                <td className="contact-column-left">Commercial Director:</td>
                <td className="contact-column-right">Gary Owen</td>
              </tr>

              <tr>
                <td className="contact-column-left">Director Of Operations:</td>
                <td className="contact-column-right">Luke Conlon</td>
              </tr>
            </table>
          </div>

          <div className="contact-group">
            <div className="contact-header-main">
              Transland Contacts – UK
            </div>

            <table className="contact-table">
              <tr>
                <td className="contact-column-left">GB Customs Manager:</td>
                <td className="contact-column-right">Darpan Patni</td>
              </tr>

            </table>
          </div>

          <div className="contact-group">
            <div className="contact-header-main">
              Transland Contacts – Ireland
            </div>

            <table className="contact-table">

              <tr>
                <td className="contact-column-left">UK & European Operations Manager:</td>
                <td className="contact-column-right">Craig Hilton</td>
              </tr>

              <tr>
                <td className="contact-column-left">Logistics/ Transport Manager:</td>
                <td className="contact-column-right">Dane Reid</td>
              </tr>

              <tr>
                <td className="contact-column-left">IE Customs Manager</td>
                <td className="contact-column-right">Darpan Patni</td>
              </tr>

              <tr>
                <td className="contact-column-left">Warehouse Managers:</td>
                <td className="contact-column-right">David Kinahan/ John Reid</td>
              </tr>

              <tr>
                <td className="contact-column-left">Fleet Manager:</td>
                <td className="contact-column-right">Hilary Simpson</td>
              </tr>

              <tr>
                <td className="contact-column-left">Solutions Manager:</td>
                <td className="contact-column-right">Dermot Hunt</td>
              </tr>

              <tr>
                <td className="contact-column-left">Marketing Manager:</td>
                <td className="contact-column-right">Fiona Williams</td>
              </tr>


              <tr>
                <td className="contact-column-left">Credit Control Manager:</td>
                <td className="contact-column-right">Lorraine Walker</td>
              </tr>



            </table>
          </div>

          <div className="contact-group">
            <div className="contact-header-main">
              Phone Numbers
            </div>

            <table className="contact-table">
              <tr>
                <td className="contact-column-left">Ireland:</td>
                <td className="contact-column-right">Tel: +353 (0)1 829 3222</td>
              </tr>

              <tr>
                <td className="contact-column-left">UK:</td>
                <td className="contact-column-right">Tel: +44 (0)1543 445656</td>
              </tr>
            </table>
          </div>

          <div className="contact-group">
            <div className="contact-header-main">
              General Enquiries
            </div>

            <table className="contact-table">
              <tr>
                <td className="contact-column-left">Email:</td>
                <td className="contact-column-right">info@translandgroup.com</td>
              </tr>
            </table>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default Contacts;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks3 from '../../assets/transland-trucks-3.jpg';
import palletways from '../../assets/customer1.png';
import palletxpress from '../../assets/customer4.png';

class CompanyProfile extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>


        <div className="content">

          <div className="partners-content-image"></div>

          <div className="partners-content-header" style={{fontSize:"1.4rem"}}>Partners</div>

          <div className="partners-content-text" style={{fontSize:"1rem"}}>
            <img src={palletways} className="partner-content-image" />
            <div className="content-subheader">Palletways</div>
            The Palletways Group is Europe’s largest dedicated provider of express distribution services for small consignments of palletised goods. Founded in 1994, Palletways now has over 400 depots across the UK and mainland Europe, and handles up to 40,000 pallets every day through 17 hub operations.
            <br/>
            The Palletways UK distribution network comprises over 110 depots, operated by independent and established logistics companies, who deliver nightly into hubs in Lichfield, London, Leeds, Edinburgh and Bristol. It offers a range of express distribution services for consignments of palletised freight across the UK. These include next day, next day AM, timed delivery options, and Economy 2/3 day.
            <br/>
            Palletways Europe GmbH, based in Switzerland, was set up in 2006 to meet the increasing demands for the movement of small consignments of palletised freight within the European market sector. Its primary hub facilities are located in Nijmegen (Netherlands), Knullwald (Germany), Paris, Bologna and Madrid, along with a number of regional hubs throughout Europe.
            <br/>
            The company’s distribution networks in the UK and mainland Europe are supported by an unrivalled and fully integrated IT infrastructure, which allows customers to track and trace consignments using the internet. Palletways also provides warehouse and fulfilment services alongside its hub locations, giving quick access to the multi-site delivery facility of the pallet network.
            <br/>
            For more information on Palletways, visit <a href="http://www.palletways.com">www.palletways.com</a>
            <br/><br/><br/>
            <img src={palletxpress} className="partner-content-image" />
            <br/><br/>
            <div className="content-subheader">Palletxpress</div>
            Transland is very proud of its association with PalletXpress, Ireland’s only independent pallet network, for Express domestic distribution of pallet and carton traffic.
            <br/>
            Since its launch in July 2004, PalletXpress has seen incredible success, growing quickly in size and reputation, and showing profit after just two months trading. The network now moves on average 3500 pallets per night, a testiment to the quality of service provided by the network’s 24 members.
            <br/>
            PalletXpress members are chosen on the basis of proven quality and commitment to the service, and are located throughout the 32 counties for comprehensive nationwide coverage. This strategy guarantees the provision of a next-day distribution service to all major towns and cities in Ireland (with a 48-hour service to more remote areas).
            <br/>
            A state-of-the-art IT system offers customers electronic proof of delivery information within seconds of delivery. Integrated barcoding technology enables tracking of all consignments from collection right through to delivery.
            <br/>
            You can learn more about PalletXpress by visiting <a href="http://www.palletxpress.com">www.palletxpress.com</a>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default CompanyProfile;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks3 from '../../assets/transland-trucks-3.jpg';

class CompanyProfile extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">
          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../about">About Us</a> / <a href="/">Company Profile</a></div>

          <div className="profile-content-header">Company Profile</div>

          <div className="profile-content-text">
          Transland Group is one of Ireland’s leading transport and logistics companies.

            Transland International was initially formed in 1993 to serve the needs of a particular magazine publisher on the UK-Ireland route. Since then, the company’s dedication to quality of service has ensured a continuous growth in size and reputation. Transland International is now the market leader in the movement of magazines and other time-critical goods between the UK and Ireland.
            <br/><br/>
            In 2002, Transland began to diversify into the palletised freight market. The company now offers an excellent distribution service within Ireland through its membership of PalletXpress, and a consistently reliable UK and European import / export service, due to its membership of the Palletways network.
            <br/><br/>
            Transland has since extended its pallet service to include Europe. The company also offers worldwide freight forwarding and groupage services.
            <br/><br/>
            Transland has offices in Dublin and Lichfield, and is a member of the following freight and logistics associations: BIFA, IIFA and CILT.
            <br/><br/><br/>
            <b>GROUP MISSION STATEMENT</b><br/><br/>
            Our mission is to provide and maintain the highest level of service to our clients in all areas of our operation, to supply this service at a competitive price, and to respond proactively to customers’ requirements.
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default CompanyProfile;

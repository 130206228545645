import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import CustomInput from '../../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../../assets/arrow-icon.png';
import UnavailableIcon from '../../../assets/unavailable.png';
import AvailableIcon from '../../../assets/available.png';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import SplitForm from '../../../components/cardForm.js';





class BookingFour extends React.Component{



  constructor(props){
    super(props);



    if(!this.props.location.state){
      this.props.history.push({
          pathname: '/booking/1'
      });
      this.state = {loading:true};
    }else{
      this.state = {
        postcode1type:'text',
        postcode2type:'text',
        dataId:this.props.location.state.POST_DATA_ID,
        expectedArrival:this.props.location.state.expectedArrival,
        days:{},
        loading:false,
        chosenDate:'',
        error:'',
        cardNumber:'',
        cardCVC:'',
        cardExpiryMonth:'',
        cardExpiryYear:'',
        cardName:'',
        cardType:'VISA',

        paymentAmount: this.props.location.state['COST OF CONSIGNMENT']['TOTAL PRICE'],
        paymentAmountNoVAT: this.props.location.state['PRICE_NO_VAT'],
        Vat:this.props.location.state['COST OF CONSIGNMENT']['TOTAL PRICE'] - this.props.location.state['PRICE_NO_VAT'],

        fromName:this.props.location.state['FROM ADDRESS'].NAME,
        fromAddress:this.props.location.state['FROM ADDRESS'].ADDRESS,
        fromCity:this.props.location.state['FROM ADDRESS'].CITY,
        fromPostcode:this.props.location.state['FROM ADDRESS'].POSTCODE,
        fromCountry:this.props.location.state['FROM ADDRESS'].COUNTRIES_CODE,
        fromInstructions:this.props.location.state['FROM ADDRESS'].INSTRUCTIONS,
        fromContact:this.props.location.state['COMPANY INFORMATION']['COMPANY NAME'],
        fromPhone:this.props.location.state['FROM ADDRESS'].PHONE,

        billingName:this.props.location.state['BILL ADDRESS'].NAME,
        billingAddress:this.props.location.state['BILL ADDRESS'].ADDRESS,
        billingCity:this.props.location.state['BILL ADDRESS'].CITY,
        billingCountry:this.props.location.state['BILL ADDRESS'].COUNTRIES_CODE,
        billingVAT:this.props.location.state['BILL ADDRESS'].VAT,
        billingPostcode:this.props.location.state['BILL ADDRESS'].POSTCODE,

        toName:this.props.location.state['TO ADDRESS'].NAME,
        toAddress:this.props.location.state['TO ADDRESS'].ADDRESS,
        toCity:this.props.location.state['TO ADDRESS'].CITY,
        toCountry:this.props.location.state['TO ADDRESS'].COUNTRIES_CODE,
        toPostcode:this.props.location.state['TO ADDRESS'].POSTCODE,
        toContact:this.props.location.state['TO ADDRESS'].NAME.split("Company:")[0],
        toPhone:this.props.location.state['TO ADDRESS'].PHONE,

        pallets:this.props.location.state['CONSIGNMENT DETAILS']['PRODUCTS'],
        collectionDate:this.props.location.state['CONSIGNMENT DETAILS']['PICKUP DATE'],

        tc1:true,
        tc2:false,
        tc3:false,
        mailingList:false,

        cards:[	{name:'Visa/ Visa Debit', code:'VISA'}, {name:'Mastercard', code:'MC'}, {name:'Laser (Ireland Only)', code:'Laser'}, {name:'UKDM (UK Only)', code:'UKDM'}],

        currencySymbols:{GBP:'£', EUR:'€'},
        currencyCode:this.props.location.state['COUNTRY DETAILS'].CURRENCY_CODE,

        loadingPromise:true
      }
    }

    console.log(this.props.location.state);

    this.cardRef = React.createRef();

  }

  componentDidMount(){

    if(this.props.location.state){
      this.loadData();
      window.scrollTo(0, 0);
    }

    const pageAccessedByReload = (
      (window.performance && window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance
          .getEntriesByType('navigation')
          .map((nav) => nav.type)
          .includes('reload')
    );
    if(pageAccessedByReload){
      //alert("You have refreshed the page. Please contact the Transland team to ensure you weren't charged for your booking.");
      this.setState({reloaded:true});
    }


    this.loadPromise();
    this.lastClick = new Date().getTime();
  }

  /*  pk_live_5SnBVEG7ssm4BKeA1W8j1bN300ci2tN6je */
  /*  pk_test_51Gs86NLRJWNxm7nyslPgZnha7wwh5gigXq5ZCkbDp7tROyFWDbbFwjVu1r2tjIoWj9edXb8HnHQFjbLFpgEdKmR000TCxhBlk8 */

  loadPromise = async()=>{
    this.stripePromise = await loadStripe('pk_live_5SnBVEG7ssm4BKeA1W8j1bN300ci2tN6je');
    this.setState({loadingPromise:false});
  }

  loadData = ()=>{

  }

  stringifyPrice = (price) =>{

    let priceString = "";
    let euros = price.toString().split('.')[0];
    let cents = price.toString().split('.')[1];


    priceString = (euros.toString().length >= 4)? euros.substring(0,1)+","+euros.substring(1,euros.length): euros;

    if(cents == undefined) cents = 0;

    //cents = 12

    cents = parseInt(Math.round(parseFloat("0."+cents) * 100));

    console.log(cents);

    priceString = (cents.toString().length == 2)? priceString+"."+cents : priceString+"."+"0"+cents;

    return priceString;

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }

  submitDetails = async()=>{

    /* let expiryMonth = (this.state.cardExpiryMonth.toString().length == 1)? "0"+(this.state.cardExpiryMonth.toString()) : this.state.cardExpiryMonth.toString();


      if(this.state.cardNumber.toString().length != 16 || this.state.cardCVC.toString().length != 3 || expiryMonth.toString().length != 2 || this.state.cardExpiryYear.toString().length != 4 || this.state.cardName.length == 0){
        this.setState({error:"Your card details are not valid!"});
        return;
      }else if(!this.state.tc2){
        this.setState({error:"Please accept the terms & conditions!"});
        return;
      }else if(!this.state.tc3){
        this.setState({error:"Please accept the Privacy Policy!"});
        return;
      } */

      if(this.lastClick && ((new Date().getTime() - this.lastClick) < 3000)){
        console.log('DOUBLE CLICK');
        return;
      }

      this.lastClick = new Date().getTime();



      if(!this.state.intentId){
        await this.cardRef.current.handleSubmit();

        console.log(this.state.payload);

        if(this.state.payload.error){
          await this.setState({loading:false});
          await this.setState({error:this.state.payload.error.message});

          return;
        }
      }

        await this.setState({loading:true});




      let bookingFourData = {
        POST_DATA_ID:this.state.dataId,
        TYPE: this.state.cardType,
        NUMBER:this.state.cardNumber.toString(),
        NAME:this.state.cardName,
        EXDATE_MONTH:this.state.cardExpiryMonth.toString(),
        EXDATE_YEAR:parseInt(this.state.cardExpiryYear),
        CVV:parseInt(this.state.cardCVC),
        ACCEPT_POLICY:this.state.tc1 ? 1:0,
        ACCEPT_TC:this.state.tc2 ? 1:0,
        MAIL_LIST_YN:this.state.mailingList ? 1:0,
        PAYMENT_METHOD:this.state.payload.paymentMethod.id
      }

      console.log(bookingFourData);

      if(this.state.intentId){
        bookingFourData["INTENT_CODE"] = this.state.intentId;
      }


      fetch(URLs.bookingFourURL,{
          method: "POST",
          body:JSON.stringify(bookingFourData),
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
          }

          })
          .then((res) => {
            if(res.status == 200){
              res.json().then(async(data)=>{

                if((!data.success) && data.auth_needed){
                  this.setState({intentId:data.message, redirectUrl:data.redirect_url});
                  console.log(data);
                  return;
                }
                await this.setState({bookingResponse:data, error:""});
                this.props.history.push({
                    pathname: '/booking/success',
                    state: {...data,postDataId:this.state.dataId}
                })
              }).catch((error)=>{
                this.setState({
                  error:"Do not attempt to rebook. Please report this issue to the Transland Team. Your payment may have been taken.",
                  loading:false
                });
              })
            }else{
              console.log(res);
              try{
                res.json().then(async(data)=>{
                  console.log(data);
                  this.setState({
                    error:"There was an error:"+(data.message?data.message:data),
                    loading:false
                  });
                });
              }catch(error){
                this.setState({
                  error:"Do not attempt to rebook. Please report this issue to the Transland Team. There was an error: "+res,
                  loading:false
                });
              }


              return;
            }
        }).catch((error)=>{
          console.log(error);
        })

  }

  changeDetails = (details, data)=>{

    switch(details){
      case('card_number'):

        if(data.length <= 16){
        this.setState({cardNumber: data.replace(' ','')});
        }

      break;

      case('card_cvc'):
        if(data.length <= 3){
          this.setState({cardCVC: data.replace(' ','')});
        }
      break;

      case('card_expiry_month'):
        if(data.length <= 2){
          this.setState({cardExpiryMonth: data.replace(' ','')});
        }
      break;

      case('card_expiry_year'):
        if(data.length <= 4){
          this.setState({cardExpiryYear: data.replace(' ','')});
        }
      break;

      case('card_name'):
          this.setState({cardName: data});
      break;

      case('card_type'):
          this.setState({cardType: data});
      break;

      case('tc2'):
          this.setState({tc2: !this.state.tc2});
      break;

      case('tc3'):
          this.setState({tc3: !this.state.tc3});
      break;

      case('mailing_list'):
          this.setState({mailingList: !this.state.mailingList});
      break;


    }


  }

  structureDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  renderPriceNoVAT = ()=>{
    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice(Math.round(((this.state.paymentAmountNoVAT.toString().length == 4)? (this.state.paymentAmountNoVAT - ((this.state.currencyCode == "GBP")? 0:0)).toString()+"0" : this.state.paymentAmountNoVAT - ((this.state.currencyCode == "GBP")? 0:0))*100)/100);
    }

    return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice((this.state.paymentAmountNoVAT.toString().length == 4)? this.state.paymentAmountNoVAT.toString()+"0" : this.state.paymentAmountNoVAT);
  }

  renderVAT = ()=>{
    let customs = 0;

    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      customs = (this.state.currencyCode == "GBP")? 90:90;
    }

    console.log(customs);

    return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice((this.state.Vat.toString().length == 1)?  "0.00" : Math.round((this.state.Vat - customs) * 100) / 100);
  }

  renderCustoms = ()=>{

    let currencyAmount = (this.state.currencyCode == "GBP")? 90:90;
    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      return(
        <tr className="price-display">
          <td style={{width:"50%"}}>Customs Clearance</td>
          <td style={{textAlign:"left", width:"50%"}}>{this.state.currencySymbols[this.state.currencyCode]}{currencyAmount+".00"}</td>
        </tr>
      )
    }
  }



  render(){
    if(false && this.state.reloaded){
      return(
        <div style={{fontSize:"1.5rem",padding:"8vh"}}>
          It appears you have reloaded the page during booking.<br/><br/>
          If you have entered your credit card details, please contact the Transland team as you may have been charged and your booking is likely complete.
        </div>
      )
    }

    if(!this.state.loading && !this.state.loadingPromise){
      return(
        <div>
          <Header></Header>

          <div className="content">
            <div className="booking-form-4">
              <div className="summary-header">Order Summary</div>

              <div className="main-booking-form-columns">


                <table className="summary-table-structure">
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}><b>Collection</b></td>
                    <td style={{width:'50%'}}><b>Delivery</b></td>
                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.fromName}</td>
                    <td style={{width:'50%'}}>{this.state.toName.split("Company:")[1]}</td>
                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%',verticalAlign:'top'}}>{this.state.fromAddress.replace(",",", ")}</td>
                    <td style={{width:'50%',verticalAlign:'top'}}>{this.state.toAddress.replace(",",", ")}</td>
                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.fromCity+" "}</td>
                    <td style={{width:'50%'}}>{this.state.toCity+" "}</td>
                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.fromPostcode}</td>
                    <td style={{width:'50%'}}>{this.state.toPostcode}</td>
                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.fromCountry}</td>
                    <td style={{width:'50%'}}>{this.state.toCountry}</td>
                  </tr>
                </table>

                <br/>



                <table className="summary-table-structure">
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.fromContact}</td>
                    <td style={{width:'50%'}}>{this.state.toContact}</td>
                  </tr>

                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.fromPhone}</td>
                    <td style={{width:'50%'}}>{this.state.toPhone}</td>
                  </tr>

                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>Instructions: {this.state.fromInstructions.split('[***]')[0]}</td>
                    <td style={{width:'50%'}}>{this.state.fromInstructions.split('[***]')[1].replace("Delivery","")}</td>
                  </tr>

                </table>

                <br/><br/>

                <table className="summary-table-structure">
                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%',paddingRight:'10px'}}><b>Billing</b></td>
                    <td style={{width:'50%'}}>Collection Date: {this.structureDate(this.state.collectionDate)}</td>
                  </tr>

                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%',paddingRight:'10px'}}>{this.state.billingName}</td>
                    <td style={{width:'50%'}}>Expected Arrival: {this.structureDate(this.state.expectedArrival)}</td>
                  </tr>

                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%',paddingRight:'10px'}}>{this.state.billingAddress.replace(",",", ")}</td>
                    <td style={{width:'50%'}}>Number of Pallets: {this.state.pallets}</td>
                  </tr>

                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.billingCity}</td>
                    <td style={{width:'50%'}}></td>
                  </tr>

                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>{this.state.billingCountry}</td>
                    <td style={{width:'50%'}}></td>
                  </tr>

                  <tr style={{width:'100%'}}>
                    <td style={{width:'50%'}}>VAT Number:{this.state.billingVAT}</td>
                    <td style={{width:'50%'}}></td>
                  </tr>
                </table>

              </div>
            </div>

            <div className="booking-form-4">
              <div className="main-booking-form-header-4">Payment Details</div>

              <br/><br/>

              <Elements stripe={this.stripePromise}>
                <ElementsConsumer>
                  {({elements, stripe}) => (
                    <SplitForm context={this} ref={this.cardRef} elements={elements} stripe={stripe} />
                  )}
                </ElementsConsumer>
              </Elements>

              <br/><br/>
              <table className="price-table">
                  <tr className="price-display">
                    <td style={{width:"50%"}}>Delivery</td>
                    <td style={{textAlign:"left", width:"50%"}}>{this.renderPriceNoVAT()}</td>
                  </tr>



                  <tr className="price-display">
                    <td style={{width:"50%"}}>VAT</td>
                    <td style={{textAlign:"left", width:"50%"}}>{this.renderVAT()}</td>
                  </tr>

                  {this.renderCustoms()}




                  <tr className="price-display">
                    <td style={{width:"50%"}}>Total</td>
                    <td style={{textAlign:"left", width:"50%"}}>{this.state.currencySymbols[this.state.currencyCode]}{this.stringifyPrice((this.state.paymentAmount.toString().length == 4)? this.state.paymentAmount.toString()+"0" : this.state.paymentAmount)}</td>
                  </tr>
              </table>

              <br/>
              <input type="checkbox" id="checkbox1" name="checkbox1" checked={this.state.tc3} onChange={(event)=>{this.changeDetails('tc3', event.target.value)}}></input>
              <label for="checkbox1"> I have read and understood <a href="/online-booking-policy.pdf">Transland's Online Shipping Policy</a></label><br/>

              <br/>
              <input type="checkbox" id="checkbox1" name="checkbox1" checked={this.state.tc2} onChange={(event)=>{this.changeDetails('tc2', event.target.value)}}></input>
              <label for="checkbox1"> I have read and understood <a href="/terms">Transland's Terms & Conditions</a></label><br/>



              <br/>
              <input type="checkbox" id="checkbox1" name="checkbox1" checked={this.state.mailingList} onChange={(event)=>{this.changeDetails('mailing_list', event.target.value)}}></input>
              <label for="checkbox1"> I would like to join Transland's Mailing List</label><br/>
              <br/>
              If you encounter a loading screen during payment for longer than 30 seconds, please contact the Transland Team. Do not attempt to resubmit your order. Please do not refresh the screen while your payment is being processed.


              <div className="booking-4-error">{this.state.error}</div>

              <div className="payment-submit-button" onClick={this.submitDetails}>Pay & Order
                <img src={arrow} className="home-form-arrow-icon" ></img>
              </div>
            </div>
        </div>

          <Footer></Footer>
        </div>
      )
    }else if(this.state.intentId){
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <iframe src={this.state.redirectUrl} style={{width:'60vw',height:'50vh', marginBottom:'40px'}} />
            <div style={{color:"white",fontSize:"1.4rem"}}>Please confirm payment authorisation with your bank above, then resubmit below. The URL for this iframe is: {this.state.redirectUrl}. If the window above does not load, do not close this window. Please try pasting it into a new window your browser, authenticating, then resubmitting here.</div>
            <div className="payment-submit-button" style={{border:"2px solid white"}} onClick={this.submitDetails}>Resubmit</div>
          </div>
        </div>
      )

    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container" style={{textAlign:'center',alignItems:'center',textAlign:'center'}}>
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
            <div style={{width:"100%",color:"white", fontSize:"1.5rem", marginTop:"40px", textAlign:"center"}}>
              Please do not reload this screen. If this screen continues for longer than 30 seconds, do not attempt to re-book. Contact the Transland Team to confirm your booking.
            </div>
          </div>
        </div>
      )
    }

  }

}

export default BookingFour;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

import shippingService from '../../assets/shipping-service.png';
import podCapture from '../../assets/pod-capture.png';
import gpsTracking from '../../assets/gps-tracking.png';

import arrow from '../../assets/arrow-icon.png';

class Technology extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="technology-image"></div>

        <div className="bread-trail"><a href="../">Home</a> / <a href="/">Downloads</a></div>

        <div className="content">

          <div className="technology-content-header" style={{fontSize:"4vh"}}>Downloads</div>

          <div className="technology-content-text" style={{fontSize:"2.2vh"}}>

            <a className="template-link" href="https://view.flipdocs.com/?ID=10028428_937806"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Company Brochure</span></a><br/>
            <a className="template-link" href="/credit-application-form.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Credit Account Application Form</span></a><br/><br/>
            
            <a className="template-link" href="/online-booking-policy.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Online Shipping Policy</span></a><br/>
            <a className="template-link" href="/irha_conditions.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>IRHA Conditions (Irish Shipments)</span></a><br/>
            <a className="template-link" href="/rha_conditions.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>RHA Conditions (UK Shipments)</span></a><br/>
            <a className="template-link" href="/cmr_conditions.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>CMR Conditions (European Shipments)</span></a><br/>
            <a className="template-link" href="/privacy-policy"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Privacy Policy</span></a><br/><br/>


            <a href="/ROI_Exporter.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Shipping from ROI to GB</span></a><br/>
            <a href="/Direct_Rep_GB.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Direct Representation Authorisation Form – GB (updated August 2023)</span></a><br/>
            <a href="/ROI_Importer_Guide.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Shipping from GB to ROI</span></a><br/>
            <a href="/Direct_Rep_IE.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Direct Representation Authorisation Form – IE (updated August 2023)</span></a><br/>
            <a href="/Revenue_Deferment.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Irish Revenue Deferment Request Form (AEP5a)</span></a><br/>
            <a href="/Commercial_Invoice_Template.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Sample Commercial Invoice Template</span></a><br/>
            <a href="/Commercial_Invoice_Checklist.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Commercial Invoice Checklist</span></a><br/>
            <a href="/special-products-information-sheet.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Special Products Information Sheet</span></a><br/><br/>

            <a href="https://trade.ec.europa.eu/access-to-markets/en/glossary/incoterms"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Incoterms</span></a><br/>
            <a href="https://www.revenue.ie/en/customs/businesses/brexit/info-businesses/vat-gb-after-transition/postponed-accounting.aspx"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Postponed VAT Accounting</span></a><br/>
            <a href="https://www.gov.uk/additional-commodity-code"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Meursing Codes</span></a><br/>


          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default Technology;

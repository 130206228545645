import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class GPSTracking extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../">Technology</a> / <a href="/">GPS Tracking System</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>GPS Tracking System</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
          Drivers’ PDA devices also incorporate a GPS Tracking System, which forms the basis of Transland’s new online tracking service.
          <br/>
          This technology allows Transland to track vehicles in real time, and provide customers with instantaneous tracking information on their consignments. The GPS tracking system also enables Transland to reduce operating costs, optimise vehicle efficiency and improve overall fleet management.
          <br/><br/>
          Through our GPS tracking system, customers can be sure that the tracking information available to them is accurate and up-to-date, without the need for phone calls.
          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default GPSTracking;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

import shippingService from '../../assets/shipping-service.png';
import podCapture from '../../assets/pod-capture.png';
import gpsTracking from '../../assets/gps-tracking.png';

import arrow from '../../assets/arrow-icon.png';

class Technology extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="technology-image"></div>

        <div className="bread-trail"><a href="../">Home</a> / <a href="/">Technology</a></div>

        <div className="content">

          <div className="technology-content-header" style={{fontSize:"4vh"}}>Technology</div>

          <div className="technology-content-text" style={{fontSize:"2.2vh"}}>
            Transland Group’s focus on distribution technology has transformed the way we do business, and by extension how we communicate with our customers, in terms of customer service.
            <br/>
            Transland’s technology focus started with a complete overhaul of the company’s internal ERP system, and has led to various software projects that have brought us to the forefront of distribution technology innovation. Click on the links below to read more about our recent technology projects:
            <br/><br/>
            Using sophisticated distribution software, we can now provide customers with accurate, up to date tracking information on their shipments, and provide Proof of Delivery (POD) information electronically at point of delivery. Furthermore, customers can now book their consignments online, and avail of special discounted rates for early booking through the Internet, saving them time and money.
            <br/>
            <div className="technology-page-container">
              <div className="technology-item">
                <img src={shippingService} className="technology-icon"></img>
                <div className="technology-header">Online Shipping Service</div>
                <Link to={'/technology_/online-shipping-services'}><div className="technology-read-more-button">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

              <div className="technology-item">
                <img src={podCapture} className="technology-icon"></img>
                <div className="technology-header">Electronic POD Capture</div>
                <Link to={'/technology_/pod-capture'}><div className="technology-read-more-button">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

              <div className="technology-item">
                <img src={gpsTracking} className="technology-icon"></img>
                <div className="technology-header">GPS Tracking System</div>
                <Link to={'/technology_/gps-tracking-system'}><div className="technology-read-more-button">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

            </div>
          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default Technology;

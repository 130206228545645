

let publicKey = {
    registerURL: "https://api.translandgroup.com/api/register",
    loginURL: "https://api.translandgroup.com/api/login",
    countriesURL: "https://api.translandgroup.com/api/countries",
    currenciesURL: "https://api.translandgroup.com/api/currencies",
    productsURL: "https://api.translandgroup.com/api/products",
    servicesURL: "https://api.translandgroup.com/api/services",
    addonsURL:"https://api.translandgroup.com/api/add-on-services",

    userDetailsURL:"https://api.translandgroup.com/api/profile",
    updateUserDetailsURL:"https://api.translandgroup.com/api/profile",
    ordersURL:"https://api.translandgroup.com/api/bookings",

    newsletterURL:"https://api.translandgroup.com/api/newsletter",

    bookingOneURL:"https://api.translandgroup.com/api/step2",
    bookingTwoURL:"https://api.translandgroup.com/api/step3",
    bookingThreeURL:"https://api.translandgroup.com/api/step4",
    bookingFourURL:"https://api.translandgroup.com/api/step5",
    localitiesURL:"https://api.translandgroup.com/api/localities",

    ukFormURL:"https://api.translandgroup.com/api/form-data",

    trackingURL:"https://api.translandgroup.com/api/tracking",

    resetPasswordURL:"https://api.translandgroup.com/api/forgot-password",

    rateRequestURL:"https://api.translandgroup.com/api/submit-rate-request",
    customsPaymentURL:"https://api.translandgroup.com/api/pay-customs",
    resendEmails:"https://api.translandgroup.com/api/resendBookingEmails"
}

let stagingKey = {
    registerURL: "https://apibackoffice.developmentoverview.com/api/register",
    loginURL: "https://apibackoffice.developmentoverview.com/api/login",
    countriesURL: "https://apibackoffice.developmentoverview.com/api/countries",
    currenciesURL: "https://apibackoffice.developmentoverview.com/api/currencies",
    productsURL: "https://apibackoffice.developmentoverview.com/api/products",
    servicesURL: "https://apibackoffice.developmentoverview.com/api/services",
    addonsURL:"https://apibackoffice.developmentoverview.com/api/add-on-services",

    userDetailsURL:"https://apibackoffice.developmentoverview.com/api/profile",
    updateUserDetailsURL:"https://apibackoffice.developmentoverview.com/api/profile",
    ordersURL:"https://apibackoffice.developmentoverview.com/api/bookings",

    newsletterURL:"https://apibackoffice.developmentoverview.com/api/newsletter",

    bookingOneURL:"https://apibackoffice.developmentoverview.com/api/step2",
    bookingTwoURL:"https://apibackoffice.developmentoverview.com/api/step3",
    bookingThreeURL:"https://apibackoffice.developmentoverview.com/api/step4",
    bookingFourURL:"https://apibackoffice.developmentoverview.com/api/step5",

    ukFormURL:"https://apibackoffice.developmentoverview.com/api/form-data",

    trackingURL:"https://apibackoffice.developmentoverview.com/api/tracking",

    resetPasswordURL:"https://apibackoffice.developmentoverview.com/api/forgot-password"
}

export default publicKey

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import FormInput from '../../components/formInput.js';

import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import SettingsInput from '../../components/settingsInput.js';

import arrow from '../../assets/arrow-icon.png';
import watermark from '../../assets/watermark.png';
import settings from '../../assets/settings.png';
import tracking from '../../assets/tracking.png';
import plus from '../../assets/plus.png';
import orders from '../../assets/order.png';

import URLs from '../../constants/Api.js';

class DashboardSettings extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:true,
                  errors:"",
                  loadingDetails:true,
                  currencySymbols:{GBP:'£', EUR:'€'},
                  orders:[]
                };


  }


  componentDidMount = async()=>{
    window.scrollTo(0, 0);
    await this.validateLogin();
    this.loadUserDetails();
  }

  logOut = async()=>{
    const cookies = new Cookies();
    await cookies.remove('accessToken',  { path: '/' });

    this.props.history.push("/client-login");
  }

  validateLogin = ()=>{

    const cookies = new Cookies();

    if(cookies.get('accessToken') == null){

      this.props.history.push("/client-login");

    }else{
      this.setState({loading:false, token:cookies.get('accessToken')});
    }

  }

  loadUserDetails = ()=>{
    const cookies = new Cookies();

    fetch(URLs.userDetailsURL,{
        method: "GET",
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+cookies.get('accessToken')
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{
              await this.setState({name:data.customer.NAME, companyName:data.customer.COMPANY, phone: data.customer.PHONE, loadingDetails:false});
              console.log(data);
            })
          }else{

            res.json().then(async(data)=>{
              console.log(data);

            });

            this.setState({
              error:"There was an error loading",
              loadingDetails:false
            });
            return;
          }
      });


  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
  }

  saveUserDetails = async()=>{
    await this.setState({loadingDetails:true});

    let userDetails = {};

    const cookies = new Cookies();

    userDetails['name'] = this.state.name;
    userDetails['company'] = this.state.companyName;
    userDetails['phone'] = this.state.phone;

    console.log(userDetails);
    fetch(URLs.updateUserDetailsURL,{
        method: "PUT",
        body:this.encodeQueryData(userDetails),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+cookies.get('accessToken')
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{
              await this.setState({loadingDetails:false,error:""});
              console.log(data);
            })
          }else{

            res.json().then(async(data)=>{
              console.log(data);
            })
            this.setState({
              error:"There was an error saving your details.",
              loadingDetails:false
            });
            return;
          }
      });
  }



  renderErrors = ()=>{
    if(this.state.error != ""){
      return(
        <div className="errors-container">
          {this.state.error}
        </div>
      )
    }
  }

  render(){
    if(this.state.loadingDetails){
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }else{
      return(
        <div>
          <Header type={"dashboardHeader"} clientName={this.state.name} context={this}></Header>
            <div className="dashboard-content-container">
              <div className="dashboard-navigation-row">
                <a href="/dashboard/orders"><div className="navigation-tile">
                  <img src={orders} className="navigation-tile-icon" />
                  <div className="navigation-tile-text">My Orders</div>
                </div></a>


                <a href="/tracking"><div className="navigation-tile">
                  <img src={tracking} className="navigation-tile-icon" />
                  <div className="navigation-tile-text">Tracking</div>
                </div></a>


                <a href="/booking/1"><div className="navigation-tile">
                  <img src={plus} className="navigation-tile-icon" />
                  <div className="navigation-tile-text">New Order</div>
                </div></a>

              </div>

              <div className="dashboard-order-history">
                <div className="dashboard-order-header">My Settings</div>
                <div className="my-settings-column">
                  <div className="settings-error">{this.state.error}</div><br/>
                  <SettingsInput type={'text'} value={this.state.name} onChange={(event)=>{this.setState({name:event.target.value})}} name={'Name*'} id={'promotionCode'}></SettingsInput>
                  <SettingsInput type={'text'} value={this.state.companyName} onChange={(event)=>{this.setState({companyName:event.target.value})}} name={'Company Name*'} id={'promotionCode'}></SettingsInput>
                  <SettingsInput type={'text'} value={this.state.phone} nChange={(event)=>{this.setState({phone:event.target.value})}} name={'Phone Number*'} id={'promotionCode'}></SettingsInput>
                  <div className="main-form-submit-button" onClick={this.saveUserDetails}>Submit
                    <img src={arrow} className="home-form-arrow-icon" ></img>
                  </div>
                </div>
              </div>

            </div>
          <Footer></Footer>
        </div>
      )
    }
  }

}

export default DashboardSettings;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class European extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="services-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../services">Services</a> / <a href="/">European Pallet Distribution</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>European Pallet Distribution</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            As a member of Palletways – Europe’s No.1 pallet network – Transland offers customers an excellent pallet distribution service throughout Europe.
            <br/><br/>
            Palletways is Europe’s largest provider of express palletised distribution comprising over 400 depots, with 17 hubs in 20 countries. This exceptional network allows us to deliver goods faster and more cost-effectively than ever. The Palletways Europe network is the standard bearer in European pallet distribution, specialising in shipments of 1-6 pallet consignments, with guaranteed daily departures and dedicated services.
            <br/><br/>
            Our European pallet distribution service is ideal for customers with regular or ad-hoc European imports and exports.
            <br/><br/>
            The following European countries are now serviced on a weekly or twice-weekly basis: Austria, Belgium, Bulgaria, Czech Republic, Estonia, France, Germany, Hungary, Italy, Latvia, Lithuania, Luxembourg, Netherlands, Poland, Portugal, Romania, Slovakia, Spain and Sweden. (Some service restrictions currently apply to/from Denmark, Finland and Sweden - please contact the office for more details.)
            <br/><br/>
            Through the Palletways online system, you can check the lead time for your European freight and get an accurate ETA for your shipment.
            <br/><br/>
            Transland is pleased to be able to offer its Irish and UK customers a daily pan-European solution for their palletised traffic.</div>
          </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default European;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

class PersonalEffects extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">


          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../services">Services</a> / <a href="/">Personal Effects</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>Personal Effects</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            Transland Group offers a limited service for shipping personal effects through our distribution network. However, due to the specific nature of these goods, the service has certain restrictions, as detailed below.
            <br/><br/>
            Unfortunately we are NOT currently shipping Personal Effects to/from the UK, due to the additional Customs requirements and restrictions caused by Brexit.
            <br/><br/>
            Unfortunately, personal effects consignments are not insured under our Standard Terms and Conditions. Therefore, we would strongly advise customers to take out their own All Risks insurance policy for personal effects / household removals consignments.
            <br/><br/>
            All Personal Effects consignments must be declared as such when booking (in order to differentiate these goods from a regular consignment of commercial goods), and must be booked as a residential delivery. A full packing list must also be provided prior to collection. All residential / non-business deliveries will be treated as an Economy service (i.e. longer transit time) and will not be delivered without a book-in date and time confirmed with the recipient. Any refusal or cancellation will incur an additional charge.
            <br/><br/>
            It is the customer’s responsibility to ensure that all personal effects / household removals consignments are professionally palletised or crated, packed and secured. This is essential in order to prevent goods being damaged or damaging other consignments while in transit. Transland Group will not accept personal effects consignments that are not professionally packed and crated, and a Wasted Collection charge or Administration Fee may apply.
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default PersonalEffects;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.png';

class AboutUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content" style={{marginLeft:"0px",paddingLeft:"0px"}}>


          <iframe style={{width:"100vw",height:"90vh"}} className="team-youtube-video" src="https://www.velocityfleet.com/telematics/tracking-link/2a78afa3-781f-46ca-8ce9-759f5363093f/" title="Ukraine Aid" frameborder="0" ></iframe>


        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default AboutUs;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import FormInput from '../../components/formInput.js';

import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";

import arrow from '../../assets/arrow-icon.png';
import watermark from '../../assets/watermark.png';

import URLs from '../../constants/Api.js';

import ClipLoader from "react-spinners/ClipLoader";

class ClientLogin extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:true,
                  errors:[],
                  email:'',
                  passwordd:''
                };

    this.handleEmailChange= this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);

  }

  handlePasswordChange = (event)=>{
    this.setState({password: event.target.value});
  }

  handleEmailChange = (event)=>{
    this.setState({email: event.target.value});
  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
    this.validateLogin();
  }

  validateLogin = ()=>{

    const cookies = new Cookies();


    if(cookies.get('accessToken')!= null){
      this.props.history.push("/dashboard/orders");
    }else{
      this.setState({loading:false});
    }

  }



  attemptLogin = async()=>{

      if(this.state.loading){
        return;
      }


      if(this.state.email == "" || this.state.password == ""){
          this.setState({error:"Your email and password cannot be empty."});
      }else{
        await this.setState({loading:true});

        try{
          fetch(URLs.loginURL,{
              method: "POST",
              body: "username="+this.state.email+"&password="+this.state.password,
              headers: {
                'Accept':       'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              })
              .then((res) => {
                if(res.status == 200){
                  res.json().then(async(result) => {
                    const cookies = new Cookies();
                    var date = new Date();
                    date.setTime(date.getTime() + result.expires_in*1000);
                    await cookies.set('accessToken', result.access_token, { expires: date});
                    console.log(result.expires_in);
                    this.props.history.push("/dashboard/orders");

                  });
                }else{

                  console.log(res);
                  this.setState({
                    error:"There was an error logging you in.",
                    loading:false
                  });
                  return;
                }
            });

        }catch(error){
          alert(error);
          await this.setState({loading:false, error:error});
        }
      }

  }

  renderErrors = ()=>{
    if(this.state.error != ""){
      return(
        <div className="errors-container">
          {this.state.error}
        </div>
      )
    }
  }

  renderLoginForm = ()=>{

    if(this.state.loading){

      return(
        <div className="login-form">
          <div className="loading-div" style={{width:'100%', textAlign:'center', paddingTop:20}}>
            <ClipLoader
              size={100}
              color={"#5f00a8"}
              loading={true}
            />
          </div>
        </div>
      )

    }else{
      return(
        <div className="login-form">
          <div className="login-form-header">Client Login</div>

          <FormInput type={'text'} name={'Email Address'} value={this.state.email} onChange={this.handleEmailChange}></FormInput>
          <FormInput type={'password'} name={'Password'} value={this.state.password} onChange={this.handlePasswordChange} id={'password'}></FormInput>

          {this.renderErrors()}

          <div className="login-submit-button" onClick={()=>{this.attemptLogin();}}>Submit
            <img src={arrow} className="login-form-arrow-icon" ></img>
          </div>
          <br/>
          <a className="login-form-link" href="/reset-password">Forgot your password?</a><br/>
          <a className="login-form-link" href="/register">Don't have an account? Register Here</a>
        </div>
      )
    }
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="background-content">
          <img src={watermark} className="watermark-background" />
          <div className="client-login-content">
            <div className="login-welcome-header">
              
            </div>

            {this.renderLoginForm()}

          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default ClientLogin;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Header from './components/header.js';
import CustomInput from './components/customInput.js';
import DatePicker from "react-datepicker";
import { ClipLoader } from "react-spinners";
import URLs from './constants/Api';
import "react-datepicker/dist/react-datepicker.css";

import registerIcon from './assets/register-icon.png';
import trackerIcon from './assets/tracker-icon.png';


import arrow from './assets/arrow-icon.png';

import irelandIcon from './assets/ireland-icon.jpg';
import ukIcon from './assets/uk-icon.jpg';
import europeIcon from './assets/europe-icon.png';

import shippingService from './assets/shipping-service.png';
import podCapture from './assets/pod-capture.png';
import gpsTracking from './assets/gps-tracking.png';

import award1 from './assets/iifa.png';
import award2 from './assets/customer4.png';
import award3 from './assets/platinumClub.png';
import award4 from './assets/logo2.png';
import award5 from './assets/bifa.jpg';
import award6 from './assets/finalist.jpg';

import Footer from './components/footer';

class App extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loadingCurrencies:true,
                  loadingCountries:true,
                  postcode1type:'text',
                  postcode2type:'text',
                  fromCountry:'',
                  fromPostcode:'',
                  toCountry:'',
                  toPostcode:'',
                  currency:'EUR',
                  promoCode:'',
                  collectionDate:(new Date().getHours() >= 16)? new Date((new Date().getTime() + 2*1000*60*60*24)) : new Date((new Date().getTime() + 1000*60*60*24)),
                  availableCountries:[],
                  availableCurrencies:[],
                  IrelandOnly:[{NAME:'Ireland',CODE:'IE'}],
                  limitedCountries:[{NAME:'Ireland',CODE:'IE'},{NAME:'United Kingdom',CODE:'UK'}],
                  quantityList:[1,2,3,4,5,6],
                  quantity:1,
                  showCookies:true
                };


    this.changeToCountry = this.changeToCountry.bind(this);
    this.changeFromCountry = this.changeFromCountry.bind(this);
  }

  componentDidMount(){
    this.loadData();
  }

  changeToCountry = (event)=>{
    this.setState({
      toCountry:event.target.value
    })
  }

  changeToPostcode = (event)=>{
    this.setState({
      toPostcode:event.target.value
    })
  }

  changeFromCountry = (event)=>{
    this.setState({
      fromCountry:event.target.value
    })
  }

  changeFromPostcode = (event)=>{
    this.setState({
      fromPostcode:event.target.value
    })
  }

  changeCollectionDate = (date)=>{
    this.setState({
      collectionDate:date
    })
  }

  changeCurrency = (event)=>{
    this.setState({
      currency:event.target.value
    })
  }

  changePromoCode = (event)=>{
    this.setState({
      promoCode:event.target.value
    })
  }

  changeQuantity = (event)=>{
    this.setState({
      quantity:event.target.value
    })
  }

  renderCookies = ()=>{
    if(!this.state.showCookies) return;

    return(
      <div className="cookies-notice">
        We use cookies to personalise content and ads. We also share usage about your usage of our site with social media & analytics partners.
        <div className="accept-cookies" onClick={()=>{this.setState({showCookies:false});}}>
          Accept Cookies
        </div>
      </div>
    )
  }

  loadData = async()=>{
    try{
      fetch(URLs.countriesURL,{
          method: "GET",
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          })
          .then((res) => {
            if(res.status == 200){
              res.json().then((data)=>{
                console.log(data);
                this.setState({loadingCountries: false, availableCountries:data, fromCountry:'NO_SELECTION', toCountry:'NO_SELECTION'});
              })
            }else{

              console.log(res);
              this.setState({
                error:"There was an error loading",
                loadingCountries:false
              });
              return;
            }
        });

        fetch(URLs.currenciesURL,{
            method: "GET",
            headers: {
              'Accept':       'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            })
            .then((res) => {
              if(res.status == 200){
                res.json().then(async(data)=>{
                  await this.setState({loadingCurrencies: false, availableCurrencies:data});
                })
              }else{

                console.log(res);
                this.setState({
                  error:"There was an error loading",
                  loadingCurrencies:false
                });
                return;
              }
          });

    }catch(error){
      alert(error);
    }

  }

  submitBooking = ()=>{



    let state = [{
      fromCountry: this.state.fromCountry,
      fromPostcode: this.state.fromPostcode,
      toCountry: this.state.toCountry,
      toPostcode: this.state.toPostcode,
      collectionDate: this.state.collectionDate,
      currency: this.state.currency,
      promoCode: this.state.promoCode,
      quantity:this.state.quantity
    }];

    this.props.history.push({
        pathname: '/booking/1',
        state: state
    })
  }


  render(){
    if(!this.state.loadingCountries && !this.state.loadingCurrencies){
      return(
        <div>
          {this.renderCookies()}
          <Header context={this}></Header>
          <div className="content">
            <div className="landing-content">



              <div className="home-booking-form">
                <div className="home-booking-form-header">Book Your Pallets Online</div>

                <div className="home-booking-form-columns">
                  <div className="home-booking-form-column-1">
                    <CustomInput type={'dropdown'} value={this.state.fromCountry} onChange={this.changeFromCountry} name={'Collection Country'} id={'country1'} options={this.state.availableCountries.sort().reverse()}></CustomInput>
                    <CustomInput type={'dropdown'}  value={this.state.toCountry}  onChange={this.changeToCountry} name={'Delivery Country'} id={'country2'} options={(this.state.fromCountry!="IE")?this.state.IrelandOnly.sort().reverse():((this.state.fromCountry=="IE")? this.state.availableCountries.sort().reverse():this.state.limitedCountries.sort().reverse())}></CustomInput>
                    <CustomInput type={'date'} value={this.state.collectionDate} onChange={this.changeCollectionDate} name={'Collection Date'} id={'date'}></CustomInput>
                    <CustomInput type={'text'} value={this.state.promoCode} onChange={this.changePromoCode} name={'Promotion Code'} id={'promotionCode'}></CustomInput>
                  </div>

                  <div className="home-booking-form-column-2">
                    <CustomInput type={'text'} value={this.state.fromPostcode} onChange={this.changeFromPostcode} name={'Collection Eircode/Postcode'} id={'postcode1'} ></CustomInput>
                    <CustomInput type={'text'} value={this.state.toPostcode} onChange={this.changeToPostcode} name={'Delivery Eircode/Postcode'} id={'postcode2'} ></CustomInput>
                    <CustomInput type={'dropdown'} name={'Currency'} value={this.state.currency} onChange={this.changeCurrency} id={'currency'} options={this.state.availableCurrencies}></CustomInput>
                    <CustomInput type={'quantity-dropdown'} name={'Number of Pallets*'} value={this.state.quantity} onChange={this.changeQuantity} id={'currency'} options={this.state.quantityList}></CustomInput>
                  </div>

                  <div style={{visibility:"hidden"}}>
                    This web application was developed by Conquer | Bespoke Software Development Agency. Conquer specialises in desktop software, web & mobile apps.
                  </div>

                </div>

                <div className="home-form-submit-button" onClick={this.submitBooking}>Submit
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div>

              </div>

              <br/><br/>

              <div className="slogan-quote-button" onClick={()=>{document.location="./booking/1"}}>Get A Quick Quote</div>
            </div>


            <div className="purple-container">

              <div className="nav-option">
                <img src={trackerIcon} className="nav-option-icon"></img>
                <div className="nav-option-text">
                  Track a Shipment
                </div>
                <a href="/tracking"><div className="nav-button">Track
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></a>
              </div>

              <div className="nav-option">
                <img src={registerIcon} className="nav-option-icon"></img>
                <div className="nav-option-text">
                  Register an online account
                </div>
                <a href="/register"><div className="nav-button">Register
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></a>
              </div>

            </div>

            <div className="welcome-container">
              <div className="welcome-header">Welcome to Transland Group</div>

              <div className="welcome-text">
              Transland Group provides a comprehensive range of transport and logistics services, in particular palletised freight distribution.

From domestic distribution to European imports and exports, we’ve got it covered. And if you ship pallets in small quantities, our Online Booking Facility offers the best pre-paid pallet rates to/from Ireland and Europe.
<br/>Transland also provides a full import/export service distribution to/from all parts of the United Kingdom including Customs Clearance services.<br/>Our expert Customs Teams in Ireland and the UK can assist you with any Brexit requirements or queries you may have.
Finally, our Global Forwarding department has the Customs Clearance know-how to look after a variety of forwarding projects from all over the world.
Contact us today and let Transland Group take care of your shipping needs, whatever they may be!

</div>
            </div>

            <div className="locations-container">
              <div className="location">
                <img src={irelandIcon} className="location-icon"></img>
                <div className="location-header">Ireland Domestic</div>
                <div className="location-text">
                  Transland provides a premium Ireland Domestic Transport service for palletised freight within Ireland
                  thorough its membership of PalletXpress, Ireland’s leading pallet network.
                </div>
                <Link to={'./services_/ireland-domestic'}><div className="location-read-more-button">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

              <div className="location">
                <img src={ukIcon} className="location-icon"></img>
                <div className="location-header">Ireland - UK - Ireland</div>
                <div className="location-text">
                  Transland offers an excellent Express pallet delivery service to and from the UK and Ireland,
                  using its distribution partners on both sides of the
                  Irish Sea to carry out collections from and deliveries to all major towns and cities on 48 hour basis.
                </div>
                <Link to={'./services_/ireland-domestic'}><div className="location-read-more-button">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

              <div className="location">
                <img src={europeIcon} className="location-icon"></img>
                <div className="location-header">European</div>
                <div className="location-text">
                  As a member of Palletways – Europe’s largest pallet network – Transland offers customers
                  an excellent European pallet distribution service, through a network of over 300 depots throughout Europe.
                </div>
                <Link to={'./services_/european'}><div className="location-read-more-button">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

            </div>

            <div className="brexit-post">
              <div className="brexit-filter"></div>

              <div className="brexit-text">
                Find out how you and your customers can successfully navigate Brexit and speed up deliveries to/from the United Kingdom
                <a href="./about-us/brexit"><div className="brexit-read-more-button">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></a>
              </div>

            </div>

            <div className="awards-container">
              <img src={award1} className="award-image"></img>
              <img src={award2} style={{width:'8vw'}} className="award-image"></img>
              <img src={award3} style={{width:'10vw'}} className="award-image"></img>
              <img src={award4} className="award-image"></img>
              <img src={award5} className="award-image"></img>
              <img src={award6} className="award-image"></img>
            </div>

            <div className="technology-container">
              <div className="technology">
                <img src={shippingService} className="technology-icon-main"></img>
                <div className="technology-header-main">Online Shipping Service</div>
                <Link to={'/technology_/online-shipping-services'}><div className="technology-read-more-button-main">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

              <div className="technology">
                <img src={podCapture} className="technology-icon-main"></img>
                <div className="technology-header-main">Electronic POD Capture</div>
                <Link to={'/technology_/pod-capture'}><div className="technology-read-more-button-main">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

              <div className="technology">
                <img src={gpsTracking} className="technology-icon-main"></img>
                <div className="technology-header-main">GPS Tracking System</div>
                <Link to={'/technology_/gps-tracking-services'}><div className="technology-read-more-button-main">Read More
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div></Link>
              </div>

            </div>

            <Footer></Footer>

          </div>


        </div>
      )
    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }
  }

}

export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.png';
import { TranslatorProvider } from "react-translate";
import translate from 'translate-google-api';
import {Translator, Translate} from 'react-auto-translate';
import CustomInput from '../../components/customInput.js';

class AboutUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                  languages:[{NAME:"English",CODE:"en"},{NAME:"Spanish",CODE:"es"},{NAME:"Czech",CODE:"cs"},{NAME:"Danish",CODE:"da"},{NAME:"Dutch",CODE:"nl"},{NAME:"Estonian",CODE:"et"},{NAME:"Finnish",CODE:"fi"},{NAME:"French",CODE:"fr"},{NAME:"German",CODE:"de"},{NAME:"Hungarian",CODE:"hu"},{NAME:"Italian",CODE:"it"},{NAME:"Latvian",CODE:"lv"},{NAME:"Lithuanian",CODE:"lt"},{NAME:"Polish",CODE:"pl"},{NAME:"Portuguese",CODE:"pt"},{NAME:"Romanian",CODE:"ro"},{NAME:"Slovak",CODE:"sk"}],
                  language:"en",

                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  changeLanguage = (event)=>{
    this.setState({language:event.target.value});
  }


  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">
          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../">Home</a> / <a href="/">Shipping Between EU and GB via Dublin</a></div>




          <Translator
              from='en'
              to={this.state.language}
              googleApiKey='AIzaSyAb4NVqXOyRBzmFMQhEp5_bE2EpsDriBYY'
            >

            <div className="about-us-content-header" style={{fontSize:"4vh"}}><Translate>Information for EU companies shipping goods to/from GB via Dublin</Translate></div><br/>
            <div id="translation-div" className="about-us-content-text" style={{fontSize:"2.2vh"}}>
            <CustomInput type={'dropdown'} value={this.state.language} onChange={this.changeLanguage} name={'Language'} id={'country1'} options={this.state.languages}></CustomInput>
            <br/><br/>
            <b><span style={{fontSize:"1.8rem"}}><Translate>How the process works</Translate></span></b><br/><br/>
            <b><Translate>Shipping Goods from EU to GB via Dublin</Translate></b><br/><br/>
            <li><Translate>Goods are shipped to Transland’s Dublin depot under the EU’s free movement agreement. The current shipping schedule from
            mainland Europe to Dublin is twice-weekly (Tuesday and Friday departures).</Translate></li><br/>
            <li><Translate>When the goods arrive in Transland’s Dublin depot, they are export cleared using the documents provided and shipped to GB on
            the next available vehicle.</Translate></li><br/>
            <li><Translate>On arrival in Transland’s GB depot, the goods are placed in a Bonded Warehouse until the import clearance process is completed.</Translate></li><br/>
            <li><Translate>During the import clearance process, the importer’s GB EORI number is used for payment of Duty and VAT.</Translate></li> <br/>
            <li><Translate>Once payment has been confirmed, the HMRC department then permits Transland to release the goods for delivery in GB.</Translate></li> <br/>
            <br/><br/>
            <b><Translate>Shipping Goods from GB to EU via Dublin</Translate></b><br/><br/>
            <li><Translate>Goods are brought to Transland’s GB depot, where they are export cleared. They are then shipped to Dublin on the next available
            vehicle.</Translate></li><br/>
            <li><Translate>Once the goods arrive in Transland’s Dublin depot, they are placed in a Bonded Warehouse until the import clearance process is
            completed.</Translate></li><br/>
            <li><Translate>During the import clearance process, the importer’s EU EORI number is used for identification purpose. However, Duty and VAT
            are paid using the Irish VAT number provided by Irish Revenue, which must be linked to the respective EU EORI number (please see
            below for more details).</Translate></li><br/>
            <li><Translate>Once paid, the Irish Revenue department then permits Transland to release the goods for delivery.</Translate></li><br/>
            <li><Translate>Transland then ships the goods to mainland Europe under the EU’s free movement agreement, and the goods are delivered. The
            current shipping schedule from Dublin to mainland Europe is once-weekly (Friday departure).</Translate></li><br/><br/>




            <b><Translate>Understanding the Duty & VAT element</Translate></b><br/><br/>
            <li><Translate>The EU company responsible for importing the goods can link their EU EORI number with Irish Revenue.</Translate></li><br/>
            <li><Translate>By linking their EU EORI number, Irish Revenue provides them with an Irish VAT number.</Translate></li><br/>
            <li><Translate>The Irish VAT number can be used to pay both Customs Duty and VAT in Ireland. Please note: Duty MUST be paid upfront as it
            is a mandatory charge and cannot be reclaimed. However, by linking the Irish VAT number with their EU EORI number, the EU
            company can choose to either pay the VAT upfront or to postpone the VAT payment (which is called Postponed VAT
            Accounting or PVA).</Translate></li>
            <div style={{marginLeft:"20px"}}><Translate>o If the EU company wishes to pay the VAT upfront, the VAT is debited immediately from their account.</Translate><br/>
            <Translate>o If the EU company wishes to avail of Postponed VAT Accounting (or PVA), they can postpone the payment of VAT in
            Ireland and then arrange a subsequent sale from their Irish account to their EU account (intra-community sale) under
            the zero-rated VAT. The EU company can apply to reclaim the VAT by submitting an Electronic VAT Refund (EVR) directly
            to the relevant tax authorities in their own member state.</Translate></div><br/><br/>

            <b><Translate>How To Register for Payment of Duty and VAT in Ireland – Steps to Follow</Translate></b><br/><br/>

            <table>

              <tr>
                <th style={{border:"2px solid #650abf", padding:"10px",width:"50%"}}><Translate>Steps</Translate></th>
                <th style={{border:"2px solid #650abf", padding:"10px",width:"50%"}}><Translate>Information Source</Translate></th>
              </tr>

              <tr style={{border:"2px solid #650abf", padding:"10px"}}>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><span style={{color:"#e62e09",fontSize:"1.2rem"}}>1. </span><Translate>Using your EU EORI number, register for an Irish VAT number</Translate></td>
                <td style={{border:"2px solid #650abf", padding:"10px"}}>
                <a href="https://www.revenue.ie/en/vat/vat-registration/how-do-you-register-for-vat/index.aspx">
                  https://www.revenue.ie/en/vat/vat-registration/how-do-you-register-for-vat/index.aspx
                </a>
                </td>
              </tr>

              <tr style={{border:"2px solid #650abf", padding:"10px"}}>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><span style={{color:"#e62e09",fontSize:"1.2rem"}}>2. </span><Translate>Link your EORI number and Irish VAT number</Translate></td>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><Translate>Contact the eCustoms Helpdesk in Irish Revenue</Translate> (<a href = "mailto: ecustoms@revenue.ie">ecustoms@revenue.ie</a>)</td>
              </tr>

              <tr style={{border:"2px solid #650abf", padding:"10px"}}>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><span style={{color:"#e62e09",fontSize:"1.2rem"}}>3. </span><Translate>When the accounts are linked, apply for Postponed VAT Accounting (PVA) if required</Translate></td>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><Translate>Contact the eCustoms Helpdesk in Irish Revenue</Translate> (<a href = "mailto: ecustoms@revenue.ie">ecustoms@revenue.ie</a>)</td>
              </tr>


              <tr style={{border:"2px solid #650abf", padding:"10px"}}>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><span style={{color:"#e62e09",fontSize:"1.2rem"}}>4. </span><Translate>When the accounts are linked, register for Customs & Excise in order to pay Duty in Ireland (which is the first port of entry into the EU)</Translate></td>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><Translate>Contact the eCustoms Helpdesk in Irish Revenue </Translate> (<a href = "mailto: ecustoms@revenue.ie">ecustoms@revenue.ie</a>)</td>
              </tr>

              <tr style={{border:"2px solid #650abf", padding:"10px"}}>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><span style={{color:"#e62e09",fontSize:"1.2rem"}}>5. </span><Translate>Once you have registered for Customs &amp; Excise, you may request a
Digital Certificate from ROS (Revenue Online Services). This allows you
to access your Irish account on the ROS Portal, access statements, top
up your account for Duty / VAT payment, and also file your Electronic
VAT Refunds (EVRs)</Translate></td>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><a href="https://www.ros.ie/ros-registration-web/ros-registration">https://www.ros.ie/ros-registration-web/ros-registration</a></td>
              </tr>

              <tr style={{border:"2px solid #650abf", padding:"10px"}}>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><span style={{color:"#e62e09",fontSize:"1.2rem"}}>6. </span><Translate>Log in to the ROS Portal using your Digital Certificate and top up your account with a minimum of €10.00 to ensure that the account is active</Translate></td>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><a href="https://www.ros.ie/ros-registration-web/ros-registration">https://www.ros.ie/ros-registration-web/ros-registration</a></td>
              </tr>

              <tr style={{border:"2px solid #650abf", padding:"10px"}}>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><span style={{color:"#e62e09",fontSize:"1.2rem"}}>7. </span><Translate>Check that your Irish VAT number is active</Translate></td>
                <td style={{border:"2px solid #650abf", padding:"10px"}}><a href="https://ec.europa.eu/taxation_customs/vies/">https://ec.europa.eu/taxation_customs/vies/</a></td>
              </tr>




            </table>

            <br/>

            <Translate>For any queries relating to the above, the eCustoms Helpdesk </Translate> (<a href = "mailto: ecustoms@revenue.ie">ecustoms@revenue.ie</a>)<Translate> will guide you through registering and linking your accounts.</Translate>


            </div>

            </Translator>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default AboutUs;

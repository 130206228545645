import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

class Services extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">
          <div className="services-image"></div>

          <div className="bread-trail"><a href="../">Home</a> / <a href="/">Services</a></div>

          <div className="services-content-header" style={{fontSize:"4vh"}}>Services</div>

          <div className="services-content-text" style={{fontSize:"2.2vh"}}>
            Over the years Transland Group has developed a wide variety of distribution services, in order to meet the needs of its customers, best utilise the company’s strengths, and exploit new oppportunities and technologies.
            <br/><br/>
            Click on the following links to learn more about Transland’s range of distribution services:
            <br/><br/>
            <ul>
              <li><a href="/services_/ireland-uk-ireland">Express Delivery Ireland - UK - Ireland</a></li>
              <li><a href="/services_/ireland-domestic">Ireland Domestic Transport</a></li>
              <li><a href="/services_/uk-domestic">UK Domestic Transport</a></li>
              <li><a href="/services_/european_pallet_distribution">European Pallet Distribution</a></li>
              <li><a href="/services_/international-freight-forwarding">International Freight Forwarding</a></li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default Services;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import FormInput from '../../components/formInput.js';
import CustomInput from '../../components/customInput.js';
import DatePicker from "react-datepicker";
import { ClipLoader } from "react-spinners";

import arrow from '../../assets/arrow-icon.png';
import watermark from '../../assets/watermark.png';
import tickIcon from '../../assets/tick.png';

import URLs from '../../constants/Api.js';

class ClientRegister extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loadingProducts:true,
                  products:[],
                  recommendedProduct:''
                };

  }

  changeLength = async(event)=>{
    await this.setState({
      length:event.target.value
    });

    this.updateChosenProduct();
  }

  changeWidth = async(event)=>{
    await this.setState({
      width:event.target.value
    });

    this.updateChosenProduct();
  }

  changeHeight = async(event)=>{
    await this.setState({
      height:event.target.value
    });

    this.updateChosenProduct();
  }

  changeWeight = async(event)=>{
    await this.setState({
      weight:event.target.value
    });


    this.updateChosenProduct();
  }

  componentDidMount(){

              fetch(URLs.productsURL,{
                  method: "GET",
                  headers: {
                    'Accept':       'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  })
                  .then((res) => {
                    if(res.status == 200){
                      res.json().then(async(data)=>{

                        data.sort(this.sortProducts);
                        
                        let product = data[0].CODE;

                        for(let count = 0;count < data.length;count++){
                          data[count].LENGTH = data[count].LENGTH * 1000;
                          data[count].WIDTH = data[count].WIDTH * 1000;
                          data[count].HEIGHT = data[count].HEIGHT * 1000;
                        }

                        await this.setState({loadingProducts: false, products:data.sort(this.sortProducts), product: product});
                      })
                    }else{

                      console.log(res);
                      this.setState({
                        error:"There was an error loading",
                        loadingCurrencies:false
                      });
                      return;
                    }
                });

  }


  updateChosenProduct = ()=>{

    if(this.state.weight!="" && this.state.height!="" && this.state.length!="" && this.state.width!=""){
        let productNotFound = true;
        let index = 0;

        while(productNotFound){
          if(index < this.state.products.length){

            let currentProduct = this.state.products[index];

            if(this.state.weight <= currentProduct.WEIGHT && this.state.height < currentProduct.HEIGHT && this.state.length < currentProduct.LENGTH && this.state.width < currentProduct.WIDTH){
              this.setState({product: currentProduct.CODE});
              productNotFound = false;
            }else{
              index++;
            }

          }else{
            productNotFound = false;
          }
        }

    }

  }

  sortProducts = (a,b)=>{

    let comparison = 0;

    const weightOne = parseFloat(a.WEIGHT);
    const weightTwo = parseFloat(b.WEIGHT);

    if(weightOne > weightTwo){
      comparison = 1;
    }else if (weightOne < weightTwo){
      comparison = -1;
    }

    if(comparison == 0){
      let aDimensions = parseFloat(a.LENGTH * a.WIDTH * a.HEIGHT);
      let bDimensions = parseFloat(b.LENGTH * b.WIDTH * b.HEIGHT);

      if(aDimensions > bDimensions){
        comparison = 1;
      }else if(aDimensions < bDimensions){
        comparison = -1;
      }
    }

    return comparison;

  }



  render(){

    if(!this.state.loadingProducts){
      return(
        <div>
          <Header></Header>

          <div className="calculator-container">
            <div className="calculator">
              <div className="main-booking-form-header" style={{textAlign:'center'}}>Enter your product dimensions below:</div>
              <div className="main-booking-form-column-1">
                <CustomInput type={'number'}  value={this.state.length}  onChange={this.changeLength} name={'Product Length (millimeters)'} id={'country2'} options={this.availableCountries}></CustomInput>
                <CustomInput type={'number'} value={this.state.width} onChange={this.changeWidth} name={'Product Width (millimeters)'} id={'date'}></CustomInput>
                <CustomInput type={'number'} value={this.state.height} onChange={this.changeHeight} name={'Product Height (millimeters)'} id={'promotionCode'}></CustomInput>
                <CustomInput type={'number'} value={this.state.weight} onChange={this.changeWeight} name={'Product Weight (kg)'} id={'promotionCode'}></CustomInput>
              </div>

              <div className="main-booking-form-column-2">
                <CustomInput type={'dropdown'} name={'Recommended Product'} value={this.state.product} onChange={this.changeProduct} id={'products'} options={this.state.products}></CustomInput>
              </div>
            </div>
          </div>

          <Footer></Footer>
        </div>
      )
    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }
  }

}

export default ClientRegister;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import FormInput from '../../components/formInput.js';

import arrow from '../../assets/arrow-icon.png';
import watermark from '../../assets/watermark.png';
import tickIcon from '../../assets/tick.png';

import URLs from '../../constants/Api.js';

class ClientRegister extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                  error:"",
                  name:"",
                  email:"",
                  company:"",
                  phone:"",
                  terms:false,
                  newsletter:false
                };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);

    this.handleTermsChange = this.handleTermsChange.bind(this);
    this.handleNewsletterChange = this.handleNewsletterChange.bind(this);

  }

  handleNameChange = (event)=>{
    this.setState({name: event.target.value});
  }

  handleEmailChange = (event)=>{
    this.setState({email: event.target.value});
  }

  handlePhoneChange = (event)=>{
    this.setState({phone: event.target.value});
  }

  handleCompanyChange = (event)=>{
    this.setState({company: event.target.value});
  }

  handleTermsChange = (event)=>{
    this.setState({terms: !this.state.terms});
  }

  handleNewsletterChange = async(event)=>{
    this.setState({newsletter: !this.state.newsletter});
  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  validateLogin = ()=>{

  }

  renderErrors = ()=>{
    if(!this.state.error == ""){
      return(
        <div className="errors-container">
          {this.state.error}
        </div>
      )
    }
  }

  attemptRegistration = async()=>{

    if(this.state.loading){ return; }

    if(this.state.name=="" || this.state.email=="" || this.state.company=="" || this.state.phone == ""){
      this.setState({
        error:"Error: Please fill in the required fields."
      });
    }else if(!this.state.terms){
      this.setState({
        error:"Error: Please agree to our terms and conditions!"
      });
    }else{
      await this.setState({loading:true});

      let regData = {
        name:this.state.name,
        email:this.state.email,
        company:this.state.company,
        phone:this.state.phone,
        newsletter:this.state.newsletter
      }

      try{
        fetch(URLs.registerURL,{
            method: "POST",
            body: JSON.stringify(regData),
            headers: {
              'Accept':       'application/json',
              'Content-Type': 'application/json'
            },
            })
            .then((res) => {
              if(res.status == 201){
              res.json().then(result => {
                this.setState({
                  error:"",
                  createdAccount:true,
                  loading:false
                });

                window.scrollTo(0, 0);
              });
              }else{
                this.setState({
                  error:"There was an error creating your account.",
                  loading:false
                });

                res.json().then(result => {
                  console.log(result);

                });
                return;


              }
          })


      }catch(error){
        alert(error);
        await this.setState({loading:false, error:error});
      }
    }

  }

  renderForm = ()=>{

    if(this.state.createdAccount){
      return(
        <div className="register-form">
          <div className="success-form-notice">
            <img src={tickIcon} className="success-icon" />
            <div className="success-title">Account Created!</div>
            <div className="success-subtitle">A securely generated password has been emailed to you.</div>
          </div>
        </div>
      )
    }else{
      return(
        <div className="register-form">

          <div className="register-form-header">Client Register</div>

          <div className="register-info-header">
          Here you can register for an Online Account with Transland Group. This will give you access to our Special Offers section at any time (pre-paid service only). You can save your collection and delivery addresses for future use.
          <br/><br/>
          Note: For security purposes, we do not retain credit / debit card details on this website.
          </div>


          <FormInput value={this.state.name} context ={this} onChange={this.handleNameChange} type={'text'} name={'Name*'} id={'name'}></FormInput>
          <FormInput value={this.state.email} context ={this} onChange={this.handleEmailChange} type={'text'} name={'Email Address*'} id={'email'}></FormInput>
          <FormInput value={this.state.company} context ={this} onChange={this.handleCompanyChange} type={'text'} name={'Company Name*'} id={'companyName'}></FormInput>
          <FormInput value={this.state.phone} context ={this} onChange={this.handlePhoneChange} type={'text'} name={'Phone*'} id={'phone'}></FormInput>

          <br/><br/>
          <input type="checkbox" id="checkbox1" name="checkbox1" checked={this.state.terms} onChange={this.handleTermsChange}></input>
          <label for="checkbox1"> I have read and understood <a href="/privacy-policy">Transland's Privacy policy</a></label><br/>

          <input checked={this.state.newsletter} onChange={this.handleNewsletterChange} type="checkbox" id="checkbox2" name="checkbox2"></input>
          <label for="checkbox2"> I would like to sign up to the Transland Newsletter</label><br/>

          {this.renderErrors()}

          <div onClick={()=>{this.attemptRegistration()}} className="register-submit-button">Submit
            <img src={arrow} className="register-form-arrow-icon" ></img>
          </div>
          <br/>
          <a className="register-form-link" href="/reset-password">Forgot your password?</a><br/>
          <a className="register-form-link" href="/client-login">Have an account? Sign In Here</a>
        </div>
      );
    }

  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="background-content">
          <img src={watermark} className="watermark-background" />
          <div className="client-register-content">
            <div className="register-welcome-header">
            </div>

            {this.renderForm()}

          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default ClientRegister;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class IrelandUK extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../services">Services</a> / <a href="/">Ireland-UK-Ireland</a></div>

          <div className="content-header" style={{fontSize:"4vh"}}>Express Pallet Delivery Ireland-Uk-Ireland</div>

          <div className="content-text" style={{fontSize:"2.2vh"}}>
            For your Express pallet delivery requirements, look no further than Transland.
            <br/><br/>
            Transland offers an excellent Express pallet delivery service to and from the UK and Ireland, using its distribution partners on both sides of the Irish Sea to carry out collections from and deliveries to all major towns and cities on an express service.
            <br/><br/>
            Through its association with Palletways – the UK’s first and leading pallet network – Transland offers customers the best possible Express pallet delivery service between the UK and Ireland. Collections and deliveries within Ireland are carried out via PalletXpress, Ireland’s first and only independent pallet network.
            <br/><br/>
            The "hub and spoke" principle of the pallet network enables depot members to maximise the efficiency of their operations and reduce costs, all of which means a more cost-effective and reliable Express pallet delivery service for you, the customer. Performance monitoring is carried out on a daily basis, so you can be sure the highest delivery standards are being constantly met.
            <br/><br/>
            We also provide our customers with an online tracking facility, and can email real-time POD information so you know straight away when your consignment has been delivered. Timed deliveries are available in both countries.
            <br/><br/>
            Consistency, communication, and extremely affordable rates make our Express pallet delivery service very popular with our customers.

            <br/><br/>
            <a href="../about-us/brexit/">For more information on shipping to/from the UK following Brexit, please click here</a>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default IrelandUK;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import Ireland from '../../assets/ireland-icon.jpg';
import UK from '../../assets/uk-icon.jpg';

class ContactUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="contact-us-image"></div>

        <div className="content">

          <div className="contact-us-content-header" style={{fontSize:"4vh"}}>Contact Us</div>

          <div className="contact-us-content-text" style={{fontSize:"1rem"}}>
            <div className="content-column">
            <img className="contact-icon" src={Ireland} /><br/>
              <span style={{color:'#7700c2'}}>DUBLIN</span><br/><br/>
              Transland Group<br/>
              Huntstown Business Park<br/>
              Cappagh Road<br/>
              Dublin 11, Ireland<br/>
              D11 HFP4<br/><br/><br/>

              Tel: +353 (0)1 8293222<br/>
              E-mail: info@translandgroup.com<br/><br/>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2378.56598632595!2d-6.343886484725734!3d53.40470327999129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48671299e41b9dcb%3A0x6eca1290fd2d5266!2sTransland%20Group!5e0!3m2!1sen!2sie!4v1589599606450!5m2!1sen!2sie" className="map-view" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>

            <div className="content-column">
              <img className="contact-icon" src={UK} /><br/>
              <span style={{color:'#7700c2'}}>UK</span><br/><br/>
              Transland International<br/>
              c/o Palletways Birmingham Depot<br/>
              Palletways Terminal 2<br/>
              Wood End Lane<br/>
              Fradley Park<br/>
              Lichfield, WS13 8NF<br/><br/>

              Tel: +44 (0)1543 445656<br/>
              E-mail: info@translandgroup.com
            </div>
          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default ContactUs;

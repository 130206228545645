import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import FormInput from '../../components/formInput.js';
import URLs from '../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../assets/arrow-icon.png';
import watermark from '../../assets/watermark.png';
import tickIcon from '../../assets/tick.png';

class Tracking extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                  sentResetEmail:false,
                  error:'',
                  email:''
                };

  }

  componentDidMount = async()=>{
    window.scrollTo(0, 0);

            if(this.props.location.state != null){
              await this.setState({trackingNumber:this.props.location.state.number});
              this.loadTrackingDetails();
            }

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }


  sendResetEmail = async()=>{

    if(this.state.email == ""){
      await this.setState({error:"Please enter your email."});
      return;
    }

    await this.setState({loading:true});

    fetch(URLs.resetPasswordURL,{
        method: "POST",
        body: "email="+this.state.email,
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(result) => {

              this.setState({
                sentResetEmail:true,
                loading:false
              })

            });
          }else{

            console.log(res);

            this.setState({
              error:"There was an error sending your reset password email. Please try again.",
              loading:false
            });
            return;
          }
      });


  }



  render(){

    if(this.state.sentResetEmail){
      return(
        <div>
          <Header></Header>
          <div className="email-sent-confirmation">
            <img src={tickIcon} className="confirmation-tick" />
            <div className="confirmation-text">
              Thank You.<br/>
              An email will be sent with your new password shortly.
            </div>
          </div>
          <Footer></Footer>
        </div>
      )
    }else if(!this.state.loading){
      return(
        <div>
          <Header></Header>

          <div className="background-content">
            <img src={watermark} className="watermark-background" />
            <div className="client-tracking-content">

              <div className="tracking-form">
                <div className="tracking-form-header">Reset Your Password</div>

                <FormInput type={'text'} name={'Email'} value={this.state.email} onChange={(event)=>{this.setState({email:event.target.value})}} id={'trackingNumber'}></FormInput>
                <div className="tracking-error">{this.state.error}</div>
                <div className="tracking-submit-button" onClick={this.sendResetEmail}>Send
                  <img src={arrow} className="tracking-form-arrow-icon" ></img>
                </div>
                <br/>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      )

    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default Tracking;

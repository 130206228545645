import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.png';

class AboutUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);

    fetch("https://api.translandgroup.com/api/resendBookingEmails",{
        method: "POST",
        body:JSON.stringify({
          TRACKING_ID:'12345678'
        }),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json'
        }

        })
        .then((res) => {
          if(res.status == 200){
            try{
              res.json().then(async(data)=>{

                if((!data.success) && data.auth_needed){
                  this.setState({intentId:data.message, redirectUrl:data.redirect_url});
                  console.log(data);
                  return;
                }
                await this.setState({bookingResponse:data, error:""});

              }).catch((error)=>{
                console.log(res);
              })
            }catch(error){


            }

          }else{
            try{
              res.json().then(async(data)=>{
                console.log(data);
                this.setState({
                  error:"There was an error:"+data,
                  loading:false
                });
              });
            }catch(error){
              this.setState({
                error:"Do not attempt to rebook. Please report this issue to the Transland Team. There was an error: "+res,
                loading:false
              });
              console.log('1st');
              console.log(res);
            }


            return;
          }
      }).catch((error)=>{
        console.log(error);
      })

  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">
          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../">Home</a> / <a href="/">About Us</a></div>

          <div className="about-us-content-header" style={{fontSize:"4vh"}}>About Us</div>

          <div className="about-us-content-text" style={{fontSize:"2.2vh"}}>
          Transland Group offers an extensive portfolio of services. This portfolio has been developed over the years,
          based on changing customer requirements and strategic business decisions. You will find more in-depth information
           on specific services by clicking on the links underneath:
            <br/>
            <div className="link-container"><Link to={'/about-us/brexit'}  className="about-us-content-link">Brexit</Link></div>
            Learn about Transland's response to Brexit and the solutions we provide.<br/>
            <div className="link-container"><Link to={'/about-us/company-profile'}  className="about-us-content-link">Company Profile</Link></div>
            History of the Group’s growth and development<br/>
            <div className="link-container"><Link to={'/about-us/management-team'}  className="about-us-content-link">Management Team</Link></div>
            The people behind the Group’s success<br/>
            <div className="link-container"><Link to={'/about-us/customers'}  className="about-us-content-link">Customers</Link></div>
            See what our customers have to say about our service<br/>
            <div className="link-container"><Link to={'/about-us/contacts'}  className="about-us-content-link">Contacts</Link></div>
            How to contact the main people in each department
          </div>

          <iframe width="900" height="506" className="team-youtube-video" src="https://www.youtube.com/embed/uNuqFOo65Gs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default AboutUs;

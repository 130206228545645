import React from 'react';
import './rateInput.css';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DatePicker from "react-datepicker";


class CustomInput extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      currentDate:new Date(),
    }
  }


  renderInputComponent = ()=>{
    if(this.props.type == 'text'){
      return(
        <div>
          <input value={this.props.value} onChange={this.props.onChange} type="text" style={{color:((this.props.red)? '#DD0000':'#787878') }} className="rate-input-textbox"></input>
        </div>
      )
    }if(this.props.type == 'textarea'){
      return(
        <div>
          <textarea value={this.props.value} onChange={this.props.onChange} type="text" style={{color:((this.props.red)? '#DD0000':'#787878') }} className="rate-input-textarea"></textarea>
        </div>
      )
    }else if(this.props.type == 'number'){
      return(
        <div>
          <input value={this.props.value} onChange={this.props.onChange} type="number" style={{color:((this.props.red)? '#DD0000':'#787878') }} className="rate-input-textbox"></input>
        </div>
      )
    }
    else if(this.props.type == 'card'){
      return(
        <div>
          <input value={this.props.value} maxLength={this.props.maxLength} onChange={this.props.onChange} type="text" className="rate-input-textbox"></input>
        </div>
      )
    }else if(this.props.type == 'password'){
      return(
        <div>
          <input type="password" onChange={this.props.onChange} value={this.props.value} className="rate-input-textbox"></input>
        </div>
      )
    }else if(this.props.type == 'date'){

      return(
        <div>
          <DatePicker onChange={this.props.onChange} selected={this.props.value} className="rate-input-date" dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />
        </div>
      )
    }else if(this.props.type == 'services-dropdown'){

      return(
        <div>
          <select  value={this.props.value} onChange={this.props.onChange} className="rate-input-dropdown">
            {
              this.props.options.map((option)=> <option key={option.ID} value={option.ID}>{option.NAME}</option>)
            }
          </select>
        </div>
      )
    }else if(this.props.type == 'quantity-dropdown'){

      return(
        <div>
          <select  value={this.props.value} onChange={this.props.onChange} className="rate-input-dropdown">
            {
              this.props.options.map((option)=> <option key={option} value={option}>{option}</option>)
            }
          </select>
        </div>
      )
    }else if(this.props.type == 'card-dropdown'){

      return(
        <div>
          <select  value={this.props.value} onChange={this.props.onChange} className="rate-input-dropdown">
            {
              this.props.options.map((option)=> <option key={option.name} value={option.code}>{option.name}</option>)
            }
          </select>
        </div>
      )
    }else if(this.props.type == 'products-dropdown'){

      return(
        <div>
          <select  value={this.props.value} onChange={this.props.onChange} className="rate-input-dropdown">
            <option disabled={true} key="Please Select" value={'NO_SELECTION'}>Please Select</option>
            {
              this.props.options.map((option)=> <option key={option.CODE} value={option.CODE}>{option.NAME}</option>)
            }
          </select>
        </div>
      )
    }
    else if(this.props.type == 'autofillCollection'){

      return(
          <select  style={{display:"inline-block",verticalAlign:"middle"}} value={this.props.value} onChange={this.props.onChange} className="rate-input-dropdown">
            <option disabled={false} key="Please Select" value={'NO_SELECTION'}>Select Address</option>
            {
              this.props.options.map((option,index)=> <option key={index} value={index}>{option['INFO']['booking']['POST_3']['FROM']['NAME']}</option>)
            }
          </select>
      )

    }else if(this.props.type == 'autofillDelivery'){

      return(
          <select  style={{display:"inline-block",verticalAlign:"middle"}} value={this.props.value} onChange={this.props.onChange} className="rate-input-dropdown">
            <option disabled={false} key="Please Select" value={'NO_SELECTION'}>Select Address</option>
            {
              this.props.options.map((option,index)=> <option key={index} value={index}>{option['INFO']['booking']['POST_3']['TO']['NAME'].split(" Company: ")[1]}</option>)
            }
          </select>
      )

    }else{

      return(
        <div>
          <select  value={this.props.value} onChange={this.props.onChange} className="rate-input-dropdown">
            <option disabled={true} key="Please Select" value={'NO_SELECTION'}>Please Select</option>
            {
              this.props.options.map((option)=> {

                let optionName = (option.NAME == "Ireland")? "Ireland / NI": option.NAME;

                return(
                  <option key={option.CODE} value={option.CODE}>{optionName}</option>
                )
              })
            }
          </select>
        </div>
      )
    }
  }

  render(){

    return(
      <div className="rate-input">
        <div className="rate-input-text">{this.props.name}</div>
        {this.renderInputComponent()}
      </div>
    )
  }

}

export default CustomInput

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import Ireland from '../../assets/ireland-icon.jpg';
import UK from '../../assets/uk-icon.jpg';
import SuccessIcon from '../../assets/tick.png';
import arrow from '../../assets/arrow-icon.png';
import URLs from '../../constants/Api';
import { ClipLoader } from "react-spinners";

import RateInput from '../../components/rateInput.js';

class ContactUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                  name:'',
                  email:'',
                  address:'',
                  company:'',
                  phone:'',
                  services:'',
                  messages:'',
                  nature:''
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  sendRequest = async()=>{

    if(this.state.message.length == 0 || this.state.name.length == 0 || this.state.company.length == 0 || this.state.email.length == 0 || this.state.phone.length == 0 || this.state.address.length == 0 || this.state.services.length == 0 || this.state.nature.length == 0){
      alert("Please enter all required details!");
      return;
    }

    await this.setState({loading:true});

    let rateData = {
      NAME:this.state.name,
      COMPANY:this.state.company,
      EMAIL:this.state.email,
      PHONE:this.state.phone,
      SERVICES:this.state.services,
      NATURE:this.state.nature,
      MESSAGE:this.state.message,
      ADDRESS:this.state.address
    }

    fetch(URLs.rateRequestURL,{
        method: "POST",
        body:JSON.stringify(rateData),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{

              console.log(data);

              this.setState({requestSent:true, loading:false});

            })


          }else{
            res.json().then(async(data)=>{

              console.log(data);

            })
          }

        }).catch((error)=>{
          console.log(error);
        })
  }

  renderRateRequestForm = ()=>{
    if(this.state.requestSent){
      return(
        <div>
          <div className="success-view">
            <img className="success-icon" src={SuccessIcon} />
            <div className="success-text">
              Your request was sent successfully!<br/>
            </div>
          </div>
        </div>
      )
    }

    return(
      <div style={{marginLeft:"6vw"}}>
        <RateInput type={'text'} value={this.state.name} onChange={(event)=>{this.setState({name:event.target.value})}} name={'Name*'} id={'promotionCode'}></RateInput>
        <RateInput type={'text'} value={this.state.company} onChange={(event)=>{this.setState({company:event.target.value})}} name={'Company*'} id={'promotionCode'}></RateInput><br/>
        <RateInput type={'text'} value={this.state.email} onChange={(event)=>{this.setState({email:event.target.value})}} name={'Email*'} id={'promotionCode'}></RateInput>
        <RateInput type={'text'} value={this.state.phone} onChange={(event)=>{this.setState({phone:event.target.value})}} name={'Phone*'} id={'promotionCode'}></RateInput><br/>
        <RateInput type={'text'} value={this.state.address} onChange={(event)=>{this.setState({address:event.target.value})}} name={'Address*'} id={'promotionCode'}></RateInput>
        <RateInput type={'text'} value={this.state.nature} onChange={(event)=>{this.setState({nature:event.target.value})}} name={'Nature Of Business*'} id={'promotionCode'}></RateInput><br/>
        <RateInput type={'textarea'} value={this.state.services} onChange={(event)=>{this.setState({services:event.target.value})}} name={'Services Required*'} id={'promotionCode'}></RateInput>
        <RateInput type={'textarea'} value={this.state.message} onChange={(event)=>{this.setState({message:event.target.value})}} name={'Message*'} id={'promotionCode'}></RateInput>
        <br/>
        <div className="main-booking-1-form-submit-button" onClick={this.sendRequest}>Submit
          <img src={arrow} className="home-form-arrow-icon" ></img>
        </div>
      </div>
    )
  }

  render(){

    if(this.state.loading){
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

    return(
      <div>
        <Header></Header>

        <div className="contact-us-image"></div>

        <div className="content-rate-request">

        <br/>  <div className="contact-us-content-header" style={{fontSize:"4vh"}}>Rate Request</div>


          <div className="contact-us-content-text" style={{fontSize:"1rem"}}>
            {this.renderRateRequestForm()}
          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default ContactUs;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import CustomInput from '../../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../../constants/Api';
import { ClipLoader } from "react-spinners";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import FormInput from '../../../components/formInput.js';

import arrow from '../../../assets/arrow-icon.png';
import UnavailableIcon from '../../../assets/unavailable.png';
import AvailableIcon from '../../../assets/available.png';

import xButton2 from '../../../assets/x-button-2.png';

class BookingTwo extends React.Component{

  constructor(props){
    super(props);

    this.pdf_file = React.createRef();
    this.pdf_file_1 = React.createRef();
    this.pdf_file_2 = React.createRef();


    if(!this.props.location.state){
      this.props.history.push({
          pathname: '/booking/1'
      });

      this.state = {loading:true};
    }else{
      this.state = {
        loadingAuthToken:true,
        postcode1type:'text',
        postcode2type:'text',
        dataId:this.props.location.state.POST_DATA_ID,
        chosenFromCountry:this.props.location.state.CHOSEN_FROM_COUNTRY,
        chosenToCountry:this.props.location.state.CHOSEN_TO_COUNTRY,
        chosenFromPostcode:this.props.location.state.CHOSEN_FROM_POSTCODE,
        chosenToPostcode:this.props.location.state.CHOSEN_TO_POSTCODE,
        days:{},
        loading:true,
        chosenDate:'',
        error:'',
        email:'',
        password:'',
        isLoggedIn:false,
        currencySymbols:{GBP:'£', EUR:'€'}
      }
    }

    console.log(this.props.location.state);

  }

  componentDidMount(){
    if(this.props.location.state){
      this.loadData();
      this.loadAuthId();
      window.scrollTo(0, 0);
    }
  }

  setDate = (date,priceNoVat)=>{
    this.setState({
      chosenDate:date,
      priceNoVat:priceNoVat
    })
  }

  handlePasswordChange = (event)=>{
    this.setState({password: event.target.value});
  }

  handleEmailChange = (event)=>{
    this.setState({email: event.target.value});
  }

  structureDate = (date)=>{
    return date.substr(0,2)+"/"+date.substr(3,2)+"/"+date.substr(8,2);
  }

  renderUkForm = ()=>{
    if(!this.state.ukFormOpen)return;
  }

  renderCustomsNotice = ()=>{
    if(!this.state.customsNoticeOpen) return;


    return(
      <div className="calculator-box-container">

        <img className="close-calculator-button" style={{zIndex:20}} src={xButton2} onClick={()=>{this.setState({customsNoticeOpen:false, toCountry:"",fromCountry:""})}} />

        <div className="calculator-box">
        <div className="calculator-box-header">Shipments between the Republic of Ireland (ROI) and Great Britain (GB)</div>
        In order to proceed with your online booking to/from GB, you must complete and upload the
        following 3 documents <u>in advance:</u><br/><br/>

          1. Commercial Invoice from the exporter containing all the relevant information to obtain
          export and import Customs Clearance.<a className="template-link" href="/Commercial_Invoice_Template.pdf"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Commercial Invoice Checklist</span></a><br/>
          2. IE Direct Representative Form authorising Transland to carry out Customs Clearance on
          behalf of the Irish company. You can download this form below.<br/>
          3. GB Direct Representative Form authorising Transland to carry out Customs Clearance on
          behalf of the GB company. You can download this form below.<br/><br/>

        <a className="template-link" href="/Commercial_Invoice_Template.pdf"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Download Commercial Invoice Template</span></a>
        <a className="template-link" href="/Direct_Rep_IE.pdf"><span style={{fontSize:"1.1rem",color:"#7009de", marginLeft:"30px",marginRight:"30px"}}>Download IE Direct Representative Form</span></a>
        <a className="template-link" href="/Direct_Rep_GB.pdf"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Download GB Direct Representative Form</span></a><br/><br/><br/>
        <div className="calculator-box-header">PLEASE UPLOAD YOUR DOCUMENTS HERE (MAX. 2MB EACH, PDF ONLY):</div>
        <br/><br/>

        <div className="file-container">
          1.Commercial Invoice (PDF)* <br/>
          <input type="file" ref={this.pdf_file} onChange={(event)=>{
            if(this.pdf_file.current.files[0].size > 2000000){
              alert("Please use a file 2Mb or less in size");
              event.target.value = null;
              return;
            }
            this.pdf_file_main = this.pdf_file.current.files[0];

          }} accept=".pdf"/>
        </div>

        <div className="file-container">
          2.IE Direct Representative Form (PDF)* <br/>
          <input type="file" ref={this.pdf_file_1} onChange={(event)=>{
            if(this.pdf_file_1.current.files[0].size > 2000000){
              alert("Please use a file 2Mb or less in size");
              event.target.value = null;
              return;
            }
            this.pdf_file_1_main = this.pdf_file_1.current.files[0];

          }} accept=".pdf"/>
        </div>

        <div className="file-container">
          3.GB Direct Representative Form (PDF)* <br/>
          <input type="file" ref={this.pdf_file_2} onChange={(event)=>{
            if(this.pdf_file_2.current.files[0].size > 2000000){
              alert("Please use a file 2Mb or less in size");
              event.target.value = null;
              return;
            }
            this.pdf_file_2_main = this.pdf_file_2.current.files[0];

          }} accept=".pdf"/>
        </div>
        <br/><br/>

        <b>We <u>CANNOT ACCEPT</u> online bookings to/from GB for the consignment types listed below – any such bookings will be cancelled and refunded.
Goods of food, animal, wood or plant origin Second-hand machinery Personal effects Hazardous goods (this applies for all routes)
        </b>
        <br/><br/>
        Online bookings to/from GB are subject to an additional Customs Clearance charge of €90.00 per consignment up to 3 Commodity Codes. After your online
        booking has been confirmed, our Customs Department will contact the importer regarding import VAT / Duty payment. For Customs-related queries, please view
        our <a href="../../uk-domestic">Brexit page</a> or email <a href="mailto:customs@translandgroup.com">customs@translandgroup.com</a>.
        <br/><br/>
        <input type="checkbox" id="checkbox2" name="checkbox2" checked={this.state.customs1} onChange={(event)=>{this.setState({customs1:!this.state.customs1})}}></input>
        <span > I confirm that the information uploaded is complete and correct, and understand that any
incomplete / incorrect information may result in delays and / or additional charges.</span>
        <br/>
        <input type="checkbox" id="checkbox3" name="checkbox3" checked={this.state.customs2} onChange={(event)=>{this.setState({customs2:!this.state.customs2})}}></input>
        <span>I understand that the eventual delivery date of my consignment will be dependent on
Customs Clearance authorisation and release, which is outside the control of Transland.</span>
        <br/>
        <input type="checkbox" id="checkbox4" name="checkbox4" checked={this.state.customs3} onChange={(event)=>{this.setState({customs3:!this.state.customs3})}}></input>
        <span >I confirm that my consignment does not contain goods of food, animal, wood or plant origin,
second-hand machinery, personal effects or hazardous goods.</span>

        <div className="uk-form-error">{this.state.ukFormError}</div> <br/>

        <div className="uk-form-submit-button" onClick={()=>{this.submitDate(true)}}>
          Proceed to booking
        </div>

        </div>

      </div>
    )
  }

  submitUkForm = async(code)=>{

    let pdf_file = this.pdf_file_main;
    let pdf_file_1= this.pdf_file_1_main;
    let pdf_file_2 = this.pdf_file_2_main;


    let formData = new FormData();

    if(pdf_file){
      formData.append('pdf_file',pdf_file);
    }else{
      await this.setState({
        ukFormError:"Please upload all forms required.",
        loadingProducts:false,
        ukFormOpen:true
      });

      return;
    }


    if(pdf_file_1){
      formData.append('pdf_file_1',pdf_file_1);
    }else{
      await this.setState({
        ukFormError:"Please upload all forms required.",
        loadingProducts:false,
        ukFormOpen:true
      });

      return;
    }

    if(pdf_file_2){
      formData.append('pdf_file_2',pdf_file_2);
    }else{
      await this.setState({
        ukFormError:"Please upload all forms required.",
        loadingProducts:false,
        ukFormOpen:true
      });

      return;
    }




    await this.setState({
      loadingProducts:true
    });



    formData.append('EORI_NO','11111111');
    formData.append('TARIC_CODE','11111111');
    formData.append('GOODS_ORIGIN','11111111');
    formData.append('GOODS_DESC','11111111');
    formData.append('GOODS_VALUE','11111111');
    formData.append('GROSS_WEIGHT','11111111');
    formData.append('NET_WEIGHT','11111111');
    formData.append('BOOKING_CODE', code);

    fetch(URLs.ukFormURL,{
        method: "POST",
        body:formData
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{

              console.log(data);

              this.props.history.push({
                  pathname: '/booking/3',
                  state: this.state.bookingResponse
              })

            })
          }else{
            res.json().then(async(data)=>{

              console.log('5');

              this.setState({
                ukFormError:JSON.stringify(data),
                customsNoticeOpen:false,
                loadingProducts:false
              });
            });
            return;
          }
      });
  }

  submitCustomsNotice = ()=>{
    if(!this.state.customs1 || !this.state.customs2 || !this.state.customs3){
      this.setState({ukFormError:"Please check all boxes above."})
    }else if(!this.pdf_file_main || !this.pdf_file_1_main || !this.pdf_file_2_main){
      this.setState({ukFormError:"Please submit all required forms."})
    }else{
      this.setState({customsNoticeOpen:false});
    }
  }





  loadData = async()=>{
    let map = this.props.location.state;

    delete map['POST_DATA_ID'];
    delete map['ZONE_FROM'];
    delete map['ZONE_TO'];
    delete map['month'];

    await this.setState({ currencySymbol: this.state.currencySymbols[map.CURRENCY_CODE]});
    let mondays = [];
    let tuesdays = [];
    let wednesdays = [];
    let thursdays = [];
    let fridays = [];

    for (const [key, value] of Object.entries(map)) {
      switch(value.DAY){
        case("Monday"):
          mondays.push(value);
        break;

        case("Tuesday"):
          tuesdays.push(value);
        break;

        case("Wednesday"):
          wednesdays.push(value);
        break;

        case("Thursday"):
          thursdays.push(value);
        break;

        case("Friday"):
          fridays.push(value);
        break;
      }

    }

    await this.setState({days:{mondays:mondays, tuesdays:tuesdays, wednesdays:wednesdays, thursdays:thursdays, fridays:fridays}})

    let userName = '', isLoggedIn = false;

    const cookies = new Cookies();

    if(cookies.get('accessToken')!= null){
      this.setState({
        isLoggedIn:true,
        loading:false,
      })
    }else{

      this.setState({
        isLoggedIn:false,
        loading:false,
      })


    }



  }

  loadAuthId = async()=>{

    const cookies = new Cookies();

    if(cookies.get('accessToken')==null){
      await this.setState({authToken:'', loadingAuthToken:false});
      return;
    }



    fetch(URLs.userDetailsURL,{
        method: "GET",
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+cookies.get('accessToken')
        },
        })
        .then((res) => {
          console.log('A');
          if(res.status == 200){
            res.json().then(async(data)=>{

              await this.setState({authToken:data.customer['ID'], loadingAuthToken:false});
              console.log(data);
            })
          }else{
            console.log('B');
            res.json().then(async(data)=>{
              console.log(data);
            })
            this.setState({
              error:"There was an error loading",
              loadingDetails:false
            });
            return;
          }
      }).catch((error)=>{
        console.log(error);
      });

  }



  stringifyPrice = (price) =>{

    let priceString = "";
    let euros = price.split('.')[0];
    let cents = price.split('.')[1] || "0";



    priceString = (euros.toString().length >= 4)? euros.substring(0,1)+","+euros.substring(1,euros.length): euros;

    priceString = (cents.length == 2)? priceString+"."+cents : priceString+"."+cents+"0";

    return priceString;

  }

  renderDay = (dayObject)=>{

    let DATE = dayObject.DATE.split('-')[2]+'-'+dayObject.DATE.split('-')[1]+'-'+dayObject.DATE.split('-')[0];

    console.log(dayObject.PRICE_NO_VAT.toString());

    if(dayObject.AVAILABLE == 0){
      return(
        <div className="date-container-unavailable">
            <img src={UnavailableIcon} className="date-icon-img" />
            <div className="date-text-2">{this.structureDate(DATE)}</div>
            <div className="date-price-main">---</div>
        </div>
      )
    }else{
      if(dayObject.DATE == this.state.chosenDate){
        return(
          <div className="date-container-selected">
              <img src={AvailableIcon} className="date-icon-img" />
              <div className="date-text-2">{this.structureDate(DATE)}</div>
              <div className="date-price-main">{this.state.currencySymbol}{this.stringifyPrice(dayObject.PRICE_NO_VAT)}</div>
          </div>
        )
      }else{
        return(
          <div className="date-container-available" onClick={()=>{this.setDate(dayObject.DATE,dayObject.PRICE_NO_VAT)}}>
              <img src={AvailableIcon} className="date-icon-img" />
              <div className="date-text-2">{this.structureDate(DATE)}</div>
              <div className="date-price-main">{this.state.currencySymbol}{this.stringifyPrice(dayObject.PRICE_NO_VAT)}</div>
          </div>
        )
      }
    }

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }

  submitDate = async(fromUK)=>{

    if(this.state.chosenDate == ''){
      await this.setState({error:'Please select an available date.'});
      return;
    }

    if(!fromUK && (this.state.chosenFromCountry == "UK" || this.state.chosenToCountry == "UK")){
      await this.setState({customsNoticeOpen:true});
      return;
    }

    await this.setState({loading:true});

    let bookingTwoData = {
      DATE:this.state.chosenDate,
      POST_DATA_ID:this.state.dataId,
    }

    const cookies = new Cookies();

    if(cookies.get('accessToken')!=null){
      bookingTwoData['AUTH_TOKEN'] = this.state.authToken;
    }

    console.log(bookingTwoData);

    let bookingTwoHeaders = {
      'Accept':       'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }



    if(cookies.get('accessToken')!= null){
      console.log(cookies.get('accessToken'));
      bookingTwoHeaders['Authorization'] = 'Bearer '+cookies.get('accessToken');
      console.log(bookingTwoHeaders);
    }

    fetch(URLs.bookingTwoURL,{
        method: "POST",
        body:this.encodeQueryData(bookingTwoData),
        headers: bookingTwoHeaders,
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{

              let newData = data;
              newData['PRICE_NO_VAT'] = this.state.priceNoVat;
              newData['CHOSEN_FROM_COUNTRY'] = this.state.chosenFromCountry;
              newData['CHOSEN_FROM_POSTCODE'] = this.state.chosenFromPostcode;
              newData['CHOSEN_TO_POSTCODE'] = this.state.chosenToPostcode;
              newData['CHOSEN_TO_COUNTRY'] = this.state.chosenToCountry;

              await this.setState({bookingResponse:newData, error:""});

              if(fromUK){
                this.submitUkForm(this.state.dataId);
                return;
              }


              this.props.history.push({
                  pathname: '/booking/3',
                  state: newData
              })
            })
          }else{
            res.json().then(async(data)=>{
              console.log(data);
            });
            this.setState({
              error:"There was an error submitting your data",
              loading:false
            });
            return;
          }
      });


  }

  attemptLogout = async()=>{

    const cookies = new Cookies();
    await cookies.remove('accessToken', {});
    await this.setState({isLoggedIn:false});
  }

  attemptLogin = async()=>{
    if(this.state.loading){
      return;
    }


    if(this.state.email == "" || this.state.password == ""){
        this.setState({error:"Your email and password cannot be empty."});
    }else{
      await this.setState({loading:true});

      try{
        fetch(URLs.loginURL,{
            method: "POST",
            body: "username="+this.state.email+"&password="+this.state.password,
            headers: {
              'Accept':       'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            })
            .then((res) => {
              if(res.status == 200){
                res.json().then(async(result) => {
                  const cookies = new Cookies();
                  await cookies.set('accessToken', result.access_token, { expires: new Date(Date.now()+result.expires_in)});
                  this.setState({isLoggedIn:true, error:'', loading:false});

                });
              }else{

                console.log(res);
                this.setState({
                  error:"There was an error logging you in.",
                  loading:false
                });
                return;
              }
          });

      }catch(error){
        alert(error);
        await this.setState({loading:false, error:error});
      }
    }
  }

  renderBooking2Login = ()=>{
    return;

    if(!this.state.isLoggedIn){
      return(
        <div>
          <div className="booking-2-login-header">Client Login (Optional)</div>

          <FormInput type={'text'} name={'Email Address'} value={this.state.email} onChange={this.handleEmailChange}></FormInput>
          <FormInput type={'password'} name={'Password'} value={this.state.password} onChange={this.handlePasswordChange} id={'password'}></FormInput>


          <div className="login-submit-button" onClick={()=>{this.attemptLogin();}}>Log In
            <img src={arrow} className="login-form-arrow-icon" ></img>
          </div>
        </div>
      )
    }else{
      return(
        <div>
          <div className="booking-2-login-header">You Are Currently Logged In.</div>
          <div className="logout-submit-button" onClick={()=>{this.attemptLogout();}}>Log Out
            <img src={arrow} className="login-form-arrow-icon" ></img>
          </div>
        </div>
      )
    }
  }



  render(){
    if(!this.state.loading && !this.state.loadingAuthToken){
      return(
        <div>
          {this.renderCustomsNotice()}
          <Header></Header>

          <div className="booking-2-content">
            <div className="calendar-container">
              <div className="booking-2-header-main">Please choose a collection date (Rates are excluding VAT & Combined Customs Charges where applicable)</div>

              <div className="calendar-column">
                <div className="calendar-header-main">Monday</div>
                {this.state.days.mondays.map((date)=>  this.renderDay(date) )}
              </div>

              <div className="calendar-column">
                <div className="calendar-header-main">Tuesday</div>
                {this.state.days.tuesdays.map((date)=>  this.renderDay(date) )}
              </div>

              <div className="calendar-column">
                <div className="calendar-header-main">Wednesday</div>
                {this.state.days.wednesdays.map((date)=>  this.renderDay(date) )}
              </div>

              <div className="calendar-column">
                <div className="calendar-header-main">Thursday</div>
                {this.state.days.thursdays.map((date)=>  this.renderDay(date) )}
              </div>

              <div className="calendar-column">
                <div className="calendar-header-main">Friday</div>
                {this.state.days.fridays.map((date)=>  this.renderDay(date) )}
              </div>


            </div>

            <div className="submit-date-container">
              <div className="booking-2-error">{this.state.error}</div>
              <div className="booking-2-submit-date-button" onClick={()=>{this.submitDate(false)}}>Submit Chosen Date
                <img src={arrow} className="home-form-arrow-icon" ></img>
              </div>
            </div>





          </div>

          <Footer></Footer>
        </div>
      )
    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default BookingTwo;

import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import translandTrucks2 from '../../assets/transland-trucks-2.jpg';

class Terms extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">
          <img src={translandTrucks2} className="content-image" />

          <div className="about-us-content-header">Our Terms & Conditions</div>

          <div className="about-us-content-text">
          Below are our terms & conditions:
            <br/><br/>
            <a href="/irha_conditions.pdf">IRHA Conditions</a><br/>
            <a href="/rha_conditions.pdf">RHA Conditions</a><br/>
            <a href="/cmr_conditions.pdf">CMR Conditions</a>

          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default Terms;

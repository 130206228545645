import React from 'react';
import './footer.css';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import footerImage from '../assets/footer-image.png';
import tickIcon from '../assets/tick.png';
import URLs from '../constants/Api';
import { ClipLoader } from "react-spinners";

class Footer extends React.Component{

  constructor(props){

    super(props);

    this.mobileApp = props.mobileApp;

    this.state = {
      name:'',
      email:'',
      submitted:false,
      error:'',
      loading:false
    }
  }

  navigate = ()=>{

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
  }


  attemptSubmit = async()=>{
    if(this.state.email=="" || this.state.name==""){
      this.setState({error:'Your email and name are required.'});
      return;
    }

    await this.setState({loading:true});

    let newsletterData = {
      email:this.state.email,
      name:this.state.name
    }

    fetch(URLs.newsletterURL,{
        method: "POST",
        body:this.encodeQueryData(newsletterData),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{
            await this.setState({submitted:true, loading:false});
            })
          }else{
            res.json().then(async(data)=>{
              console.log(data);
            });
            this.setState({
              error:"There was an error submitting your data",
              submitted:false,
              loading:false
            });
            return;
          }
      });

  }

  renderNewsletterForm = ()=>{
    if(this.state.submitted){
      return(
        <div>
          <img style={{width:'72px',height:'72px', position:'relative',left:'20%'}} src={tickIcon} />
        </div>
      )
    }else if(this.state.loading){
      return(
        <div style={{paddingTop:'60px', paddingBottom:'60px', position:'relative', left:'10%'}}>
          <ClipLoader
            size={160}
            color={'#FFFFFF'}
            loading={true}
          />
        </div>
      )

    }else{
      return(
        <div className="footer-newsletter-form">
          <div className="footer-newsletter-header">Sign up for our newsletter!</div>
          <input type="text" placeholder="Name" value={this.state.name} onChange={(event)=>{ this.setState({name:event.target.value}) }} className="footer-newsletter-input"></input>
          <input type="text" placeholder="Email" value={this.state.email} onChange={(event)=>{ this.setState({email:event.target.value}) }} className="footer-newsletter-input"></input>
          <div className="footer-newsletter-error" style={{color:'white'}}>{this.state.error}</div>
          <div className="footer-newsletter-button" onClick={this.attemptSubmit}>Submit</div>

          <div className="footer-copyright-notice">&copy; 2021 Transland Group</div>
        </div>
      )
    }
  }

  render(){

    if(this.mobileApp) return;

    return(
      <div className="footer">

        {this.renderNewsletterForm()}

        <div style={{zIndex:8000}} className="social-media-footer-icons">
        
          <div style={{display:"inline-block",verticalAlign:"middle",marginRight:"30px"}} >
          <div className="footer-copyright-notice" style={{display:"inline-block",verticalAlign:"middle"}}><a style={{color:"white"}} href="/privacy-policy">Privacy Policy |</a></div>
          <div className="footer-copyright-notice" style={{display:"inline-block",verticalAlign:"middle"}}><a style={{color:"white"}} href="/rate-request"> | Rate Request</a></div>
          </div>
          <a href="https://business.facebook.com/translandgroup"><img src="https://translandbookings.com/facebook.png" className="social-media-footer-icon" /></a>
          <a href="https://twitter.com/translandgroup"><img src="https://translandbookings.com/twitter.png" className="social-media-footer-icon" /></a>
          <a href="https://www.linkedin.com/company/1038142/"><img src="https://translandbookings.com/linkedin.png" className="social-media-footer-icon" /></a>
          <a href="https://instagram.com/_translandgroup"><img src="https://translandbookings.com/insta.png" className="social-media-footer-icon" /></a><br/>


        </div>

        <img className="footer-image" src={footerImage}></img>
      </div>
    )
  }


}


export default Footer
